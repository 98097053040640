import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import util from 'util';
import moment from 'moment';
import axios from 'axios';
import constClass from '../../Constants/Constants'

const NavBar = (props) => {
  const { user, userActions, settings } = props;
  const [, setCalendar] = useState();
  const history = useHistory();

  const handleTitleClick = () => {
    userActions.refresh();
    history.replace(generatePath(`${props.match.path}`, { shopId: props.match.params.shopId }));
  }

  const handleLogoutClick = () => {
    localStorage.removeItem('jwt');
    userActions.logout();
    history.replace(generatePath(`${props.match.path}login`, { shopId: props.match.params.shopId }));
  }

  // const handleDispCloseClick = () => {
  //   if (window.confirm(`発券を停止しますか？`)) {
  //     var data = {
  //       shop_id: props.match.params.shopId,
  //       open_type: constClass.SETTING.OPEN_DISP_FLG,
  //       open_flg: constClass.CLOSE,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`発券を停止しました`);
  //   }
  // }

  // const handleLineCloseClick = () => {
  //   if (window.confirm(`LINEの発券を停止しますか？`)) {
  //     var data = {
  //       shop_id: props.match.params.shopId,
  //       open_type: constClass.SETTING.OPEN_LINE_FLG,
  //       open_flg: constClass.CLOSE,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`LINEの発券を停止しました`);
  //   }
  // }

  // const handleDispOpenClick = () => {
  //   if (window.confirm(`発券を再開しますか？`)) {
  //     var data = {
  //       shop_id: props.match.params.shopId,
  //       open_type: constClass.SETTING.OPEN_DISP_FLG,
  //       open_flg: constClass.OPEN,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`発券を再開しました`);
  //   }
  // }

  // const handleLineOpenClick = () => {
  //   if (window.confirm(`LINEの発券を再開しますか？`)) {
  //     var data = {
  //       shop_id: props.match.params.shopId,
  //       open_type: constClass.SETTING.OPEN_LINE_FLG,
  //       open_flg: constClass.OPEN,
  //     };
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data);
  //     window.alert(`LINEの発券を再開しました`);
  //   }
  // }

  const handleWaitCloseClick = () => {
    const jwt = localStorage.getItem('jwt');
    if (window.confirm(`順番待ちを停止しますか？`)) {
      var data = {
        shop_id: props.match.params.shopId,
        open_type: constClass.SETTING.OPEN_WAIT_FLG,
        open_flg: constClass.CLOSE,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      window.alert(`順番待ちを停止しました`);
    }
  }

  const handleWaitOpenClick = () => {
    const jwt = localStorage.getItem('jwt');
    if (window.confirm(`順番待ちを開始しますか？`)) {
      var data = {
        shop_id: props.match.params.shopId,
        open_type: constClass.SETTING.OPEN_WAIT_FLG,
        open_flg: constClass.OPEN,
      };
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/calendar/change/`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      window.alert(`順番待ちを開始しました`);
    }
  }

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    async function fetchData() {
      const date = moment().format('YYYYMMDD');
      try {
        const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/calendar/${props.match.params.shopId}/${date}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        setCalendar(data);
      } catch (err) {
        setCalendar(null);
      };
    }
    fetchData();
  }, [props.match.params.shopId]);

  return (
    <nav className="navbar navbar-light navbar-expand-lg  bg-light fixed-top">
      <Link className="navbar-brand" onClick={handleTitleClick} to={generatePath(props.match.path, { shopId: props.match.params.shopId })}>
        {settings && settings.find(s => s.setting_type === 'TITLE_SHORT_NAME') ? settings.find(s => s.setting_type === 'TITLE_SHORT_NAME').setting_val : '順番待ち'}<div className='d-none'>{util.inspect(history)}</div>
      </Link>
      {
        user.isLoggedIn &&
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      }
      {
        user.isLoggedIn &&
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { shopId: props.match.params.shopId })}`}>窓口管理</Link>
            </li>
             <li className="nav-item">
              <a className="nav-link" href={`/${props.match.params.shopId }/display`} target="_blank" rel="noreferrer">整理券発行</a>
            </li>
            {user.userClass === constClass.CLASS.ADMIN && (
              <li className="nav-item dropdown">
                <button className="nav-link nav-btn dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  マスタ管理
                </button>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}user`, { shopId: props.match.params.shopId })}`} rel="norefferer">ユーザーマスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}setting`, { shopId: props.match.params.shopId })}`}>設定マスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}calendar`, { shopId: props.match.params.shopId })}`}>カレンダーマスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}shop`, { shopId: props.match.params.shopId })}`}>店舗マスタ</Link>
                  <Link className="dropdown-item" to={`${generatePath(`${props.match.path}entrytype`, { shopId: props.match.params.shopId })}`}>選択肢マスタ</Link>
                </div>
              </li>
            )}
            <li className="nav-item dropdown">
              <button className="nav-link nav-btn dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                設定
              </button>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <button className="dropdown-item" onClick={handleWaitOpenClick}>順番待ち開始</button>
                <button className="dropdown-item" onClick={handleWaitCloseClick}>順番待ち停止</button>
              </div>
            </li>
            {user.userClass === constClass.CLASS.ADMIN && (
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}HistoryDL`, { shopId: props.match.params.shopId })}`}>履歴データ出力</Link>
              </li>
            )}
            <li className="nav-item">
              <a className="nav-link" href="../../pdf/LINE順番待ちサービス操作マニュアル_Ver1.2.pdf" target="_blank" rel="noreferrer">マニュアル</a>
            </li>
          </ul>
        </div>
      }
      {
        user.isLoggedIn &&
        <div>
          <label className="mr-2 text-primary">{user.userName}</label>
          <button className="btn btn-dark" onClick={handleLogoutClick}>ログアウト</button>
        </div>
      }
    </nav>
  );
}

export default NavBar;