import { combineReducers, configureStore } from '@reduxjs/toolkit';

import User from '../Slices/User';

const rootReducer = combineReducers({
  User: User.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;