import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import Modal from 'react-modal';
import Common from '../Common/common.js';

const BackyardOrderFin = (props) => {
  const { user, settings } = props;
  const [confirm, setConfirm] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [entryType, setEntryType] = useState(null);
  const { addToast, removeAllToasts } = useToasts();
  const [entryName, setEntryName] = useState({});

  const modalConfirmStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right:0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '20%',
      right: '20%',
      top: '25%',
      bottom: 'auto',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto",
      maxHeight: "60%",
      width: "60%",
      overflowX: "hidden",
      overflowY: "scroll",
    }
    

  };

  const idSetter = async (orderId) => {
    setOrderId(orderId);
    getEntryName(orderId);
    setConfirm(true);
  }

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    var reg_params = {};
    // if (props.statusIdx === 0) {
    //   reg_params = {
    //     "operator": "or",
    //     "where": [
    //       {
    //         "status": constClass.STATUS.FIN
    //       },
    //     ]
    //   }
    // } else 
    if (props.statusIdx === 1) {
      reg_params = {
        "shopId": props.match.params.shopId,
        "operator": "and",
        "where": [
          {
            "shop_id": props.match.params.shopId,
          },
          {
            "operator": "in",
            "attr": "status",
            "val": [constClass.STATUS.ABS, constClass.STATUS.AABS]
          },
        ]
      }
    }else if (props.statusIdx === 2){
      reg_params = {
        "shopId": props.match.params.shopId,
        "operator": "and",
        "where": [
          {
            "shop_id": props.match.params.shopId,
          },
          {
            "operator": "in",
            "attr": "status",
            "val": [constClass.STATUS.FIN]
          },
        ]
      }
    } else if (props.statusIdx === 3) {
      reg_params = {
        "shopId": props.match.params.shopId,
        "operator": "and",
        "where": [
          {
            "shop_id": props.match.params.shopId,
          },
          {
            "operator": "in",
            "attr": "status",
            "val": [constClass.STATUS.CCL, constClass.STATUS.ACCL, constClass.STATUS.UCCL]
          },
        ]
      }
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.order_id - b.order_id);
    data.map((d, idx) => (
      d.t_entry.sort((a, b) => a.entry_seq - b.entry_seq)
    ));
    setOrderData(data);
  },[props.match.params.shopId, props.statusIdx])

  const getEntryType = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "operator": "and",
      "where": [
        { "shop_id": props.match.params.shopId },
      ]
    }
    const entry_type = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    entry_type.sort((a, b) => a.entry_type < b.entry_type ? -1 : 1);
    setEntryType(entry_type);
  },[props.match.params.shopId]);

  const getEntryName = async (orderId) => {
    const jwt = localStorage.getItem('jwt');
    const entry_name = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/entry/search/${orderId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    var temp = {...entryName};
    temp['entry_name'] = entry_name;
    setEntryName(temp);
  }

  const handleStatusButtonClick = async (order_id, status) => {
    const jwt = localStorage.getItem('jwt');
    if(user.userClass === constClass.CLASS.ADMIN && status === constClass.STATUS.CALL && Common.getSettingValue(settings, 'CALL_WINDOW_FUNCTION') === '1'){
      window.alert('管理者は呼出操作ができません');
      return;
    }
    if (orderData.find(o => o.order_id === order_id).status === status) {
      return;
    }
    var call = orderData.find(o => o.user_id === user.userId && [constClass.STATUS.CALL].includes(o.status));
    if (status === constClass.STATUS.CALL && call) {
      addToast(`対応中の${call.receipt_num}番を完了してください。`, { appearance: 'error', autoDismiss: true });
      return;
    } else {
      removeAllToasts();
    }
    const params = {
      status,
      user_id: [constClass.STATUS.CALL, constClass.STATUS.ABS, constClass.STATUS.FIN, constClass.STATUS.CCL].includes(status) ? user.userId : null,
    };
    try {
      setLockData(true);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${order_id}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      await refreshOrder();
      setLockData(false);
    }
  }

  const renderButton = (data, status, statusName, additionalStatus = []) => {
    additionalStatus.push(status);
    statusName = [Common.getSettingValue(settings, 'LABEL_STATUS_CALL'), constClass.STATUS_NAME.PRE].includes(statusName) && additionalStatus.includes(data.status) ? statusName + '中' : statusName;
    return (
      <button type="button"
        disabled={lockData}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
        onClick={() => handleStatusButtonClick(data.order_id, status)}>
        {statusName}
      </button>
    )
  }

  useEffect(() => {
    var intervalId;
    async function fetchData() {
      await getEntryType();
      await refreshOrder();
      intervalId = setInterval(() => {
        refreshOrder();
      }, 5000);
    }
    fetchData();
    return () => {
      clearInterval(intervalId);
    };
}, [getEntryType, refreshOrder]);

  const getEntryTypeName = (idx, entryType) => {
    let entry_disp;
    if (settings && entryType) {
      entry_disp = Common.getSettingValue(settings, 'ENTRY_DISP_' + idx);
      if (entry_disp) {
        const ret = entryType.find(d => d.entry_type === entry_disp) ? entryType.find(d => d.entry_type === entry_disp).entry_type_short_name : '-';
        return (<td className="text-center">{ret}</td>);
      }
    }
  }

  const getEntryData = (idx, t_entry) => {
    let entry_disp;
    if (settings && t_entry) {
      entry_disp = Common.getSettingValue(settings, 'ENTRY_DISP_' + idx);
      if (entry_disp) {
        const ret = t_entry.filter(d => d.entry_type === entry_disp) ? t_entry.filter(d => d.entry_type === entry_disp).map(d => d.entry_name).join(' ') : '-';
        return (<td className="text-center align-middle">{ret}</td>);
      }
    }
  }

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    順番
                  </td>
                  <td className="text-center">
                    整理券
                  </td>
                  <td className="text-center">
                    発券時刻
                  </td>
                  {getEntryTypeName(1, entryType)}
                  {getEntryTypeName(2, entryType)}
                  {getEntryTypeName(3, entryType)}
                  {getEntryTypeName(4, entryType)}
                  <td className="text-center">
                    種別
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                  <td className="text-center">
                    {Common.getSettingValue(settings, 'LABEL_STATUS_FIN')}
                  </td>
                  <td className="text-center">
                    {Common.getSettingValue(settings, 'LABEL_STATUS_CCL')}
                  </td>
                </tr>
              </thead>
              <tbody>
                {orderData.map((data, idx) => (
                  // {orderData.filter(o => o[constClass.COLUMN.CLASS] === String(user.userClass)).map((data, idx) => (
                  <tr key={data.order_id}>
                    {/* 順番 */}
                    <td className="text-center align-middle">
                      {idx + 1}
                    </td>
                    {/* 整理券 */}
                    <td className="text-center align-middle">
                      <button className="btn btn-secondary" onClick={() => { idSetter(data.order_id) }}>
                        {data.receipt_num ? data.receipt_num : '-'}
                      </button>
                    </td>
                    {/* 発券時刻 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('HH:mm') : '-'}
                    </td>
                    {/* 入力項目１ */}
                    {getEntryData(1, data.t_entry)}
                    {/* 入力項目２ */}
                    {getEntryData(2, data.t_entry)}
                    {/* 入力項目３ */}
                    {getEntryData(3, data.t_entry)}
                    {/* 入力項目４ */}
                    {getEntryData(4, data.t_entry)}
                    {/* 種別 */}
                    <td className="text-center align-middle">
                      {data.line_id === constClass.PAPER_ORDER ? '紙' : 'LINE'}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.CALL, Common.getSettingValue(settings, 'LABEL_STATUS_CALL'))}
                      {renderButton(data, constClass.STATUS.ABS, Common.getSettingValue(settings, 'LABEL_STATUS_ABS'), [constClass.STATUS.AABS])}
                    </td>
                    {/* 入店 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.FIN, Common.getSettingValue(settings, 'LABEL_STATUS_FIN'))}
                    </td>
                    {/* 取消 */}
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.CCL, Common.getSettingValue(settings, 'LABEL_STATUS_CCL'), [constClass.STATUS.ACCL,constClass.STATUS.UCCL])}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}

            
      <Modal isOpen={confirm} style={modalConfirmStyle}>
        <div className="modal-container">
          <header className="bg-light modal-h">
            <div className="row mt-0 mx-0 ">
              <div className="col text-center ">
                <h4 className="mt-2">{`整理券発行内容`}</h4>
              </div>
            </div>
          </header>
          <div className="">
            {orderData !== null && orderData.filter(o => o.order_id === orderId).map((data) => ( 
              <div key ={data.order_id}>
                <table className=" table-bordered table-striped " width="100%">
                  <tbody>
                    {/* 整理券 */}                    
                    <tr className="mt-3 mx-0 " >
                      <th className="p-1 pl-2 pr-2 text-center" width="60%">
                        整理券
                      </th>
                      <td className="p-1 pl-2 pr-2 text-center">
                        {data.receipt_num ? data.receipt_num : '-'}
                      </td>
                    </tr>
                    {/* 発券時刻 */}                    
                    <tr className="mt-3 mx-0 ">
                      <th className="p-1 pl-2 pr-2 text-center"> 
                        発券時刻
                      </th>
                      <td className="p-1 pl-2 pr-2 text-center">
                        {data.ins_date ? moment(data.ins_date).format('HH:mm') : '-'}
                      </td>
                    </tr>
                    {/* 種別 */}                    
                    <tr className="mt-3 mx-0 ">
                      <th className="p-1 pl-2 pr-2 text-center">              
                        種別
                      </th>
                      <td className="p-1 pl-2 pr-2 text-center">
                        {data.line_id === constClass.PAPER_ORDER ? '紙' : 'LINE'}
                      </td>
                    </tr>
                    {/* ステータス */}                    
                    <tr className="mt-3 mx-0 ">
                      <th className="p-1 pl-2 pr-2 text-center">              
                        ステータス
                      </th>
                      <td className="p-1 pl-2 pr-2 text-center">
                      {data.status_name}
                      </td>
                    </tr>
                    {/* m_entry_dataが存在しない場合またはinput_typeがlabelでない場合表示する */}
                    {entryType !== null && entryType.filter(data => data.m_entry_data.length === 0 || data.m_entry_data[0].input_type !== "label").map((data, idx) => (
                      <tr className="mt-3 mx-0 " key={idx} >
                        <th className="p-1 pl-2 pr-2 text-center">
                          {data.entry_type_name}
                        </th>
                        <td className="p-1 pl-2 pr-2 text-center" >
                          {entryName && entryName.entry_name && entryName.entry_name.map((entryData) => {
                            if(entryData.entry_type === data.entry_type){
                              if(entryData.entry_data !== null){
                                return entryData.entry_name + " "; 
                              }else{
                                return ''
                              }
                            }else{
                              return '';
                            }
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <footer className="bg-light modal-f">
            <div className="row my-0 mx-5 mb-0">
              <div className="col text-center ">
                <button className={`btn btn-blue-enable w-50 py-2`}
                  onClick={() => { setConfirm(false) }}>
                  <h4 className="font-weight-bold mb-0">戻る</h4>
                </button>
              </div>
            </div>
          </footer>
        </div>
      </Modal>
    </div>
  )
}

export default BackyardOrderFin;