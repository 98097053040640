import React from 'react';

const ControlText = (props) => {

  const fontLabel = props.target === "sp" ? "" : "font-h3";
  const fontText = props.target === "sp" ? "" : "font-h3";

  return (
    <div className="text-center bg-lightwhite w-100 p-1 ml-1">
      {props.entryData.entry_name && <label className={`w-25 ${fontLabel}`}>{props.entryData.entry_name}</label>}
      <input
        type="text"
        autoComplete="off"
        disabled={props.disabled}
        className={props.entryData.entry_name ? `w-75 h-100 py-2 ${fontText}` : `w-100 h-100 py-2 ${fontText}`}
        name={props.entryData.param_name}
        value={props.value || ""}
        onChange={(e) => { props.setState(props.entryData.param_name, e.target.value) }} />
    </div>
  )

}

export default ControlText;
