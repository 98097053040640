var Constants = {};

Constants.STATUS = {
  REG: 'REG',
  PRE: 'PRE',
  ACCL: 'ACCL',
  UCCL: 'UCCL',
  AABS: 'AABS',
  CALL: 'CALL',
  ABS: 'ABS',
  CCL: 'CCL',
  FIN: 'FIN',
};

Constants.STATUS_NAME = {
  REG: '登録済み',
  PRE: '事前呼出',
  ACCL: '取消(自動)',
  UCCL: '取消(お客様)',
  AABS: '不在(自動)',
  CALL: '呼出',
  ABS: '不在',
  CCL: '取消',
  FIN: '入店',
};

Constants.PAPER_ORDER = 'PAPER_ORDER';

Constants.TIME_NAME = {
  RECEIPT_START: 'receipt_date_start',
  RECEIPT_END: 'receipt_date_end',

};

Constants.METHOD = {
};

Constants.METHOD_NAME = {
};

Constants.CLASS = {
  COUNTER: '1',
  ADMIN: '2',
  UNKNOWN: '3',
};

Constants.CLASS_NAME = {
  COUNTER: '窓口',
  ADMIN: '管理者',
  UNKNOWN: '未定',
};

Constants.PAYMENT = {

};

Constants.PAYMENT_NAME = {
};

Constants.CHANNEL = {
};

Constants.HEADING = {
  ADULT_NAME: 'お客様の人数を入力してください',
  CHILD_NAME: 'そのうち3歳以下のお子様の人数',
  COUNTER_NAME: 'カウンター席でもよろしいでしょうか',
  ADULT_SHORT: '大小',
  CHILD_SHORT: '未',
  COUNTER_SHORT: 'カウンター',
}

Constants.COUNTER = {
  COUNTER: '1',
  TABLE: '2',
};

Constants.COUNTER_NAME = {
  '1': 'OK',
  '2': 'NG',
  COUNTER: 'OK',
  TABLE: 'NG',
};

Constants.COLUMN = {
  ADULT: 'data1',
  CHILD: 'data2',
  COUNTER: 'data3',
};

Constants.SAIBAN_CLASS = {
  RECEIPT: 'RECEIPT',
};

Constants.RECEIPT_NUM = {
};

//Constants.SHOP_ID = 1;

Constants.REQUEST = "request";
Constants.CANCEL = "cancel";

Constants.SETTING = {
  INTERVAL_ABS: "INTERVAL_ABS",
  INTERVAL_CCL: "INTERVAL_CCL",
  INTERVAL_BATCH: "INTERVAL_BATCH",
  CAL_DISP_DEFAULT_START: "CAL_DISP_DEFAULT_START",
  CAL_DISP_DEFAULT_END: "CAL_DISP_DEFAULT_END",
  CAL_LINE_DEFAULT_START: "CAL_LINE_DEFAULT_START",
  CAL_LINE_DEFAULT_END: "CAL_LINE_DEFAULT_END",
  CAL_DISP_HOLIDAY_START: "CAL_DISP_HOLIDAY_START",
  CAL_DISP_HOLIDAY_END: "CAL_DISP_HOLIDAY_END",
  CAL_LINE_HOLIDAY_START: "CAL_LINE_HOLIDAY_START",
  CAL_LINE_HOLIDAY_END: "CAL_LINE_HOLIDAY_END",
  OPEN_DISP_FLG: "OPEN_DISP_FLG",
  OPEN_LINE_FLG: "OPEN_LINE_FLG",
  OPEN_WAIT_FLG: "OPEN_WAIT_FLG",
  LIMIT_PRE: "LIMIT_PRE",
  OPERATE_DATE: "OPERATE_DATE",
  VOICE_USE_FLG: "VOICE_USE_FLG",
  VOICE_RATE: "VOICE_RATE",
  VOICE_PITCH: "VOICE_PITCH",
}

Constants.OPEN = "1";
Constants.CLOSE = "2";

Constants.ACTIVE_BUTTONS = {
  'REG': [
    'PRE',
  ],
  'PRE': [
    'CALL',
  ],
  'ABS': [
    'FIN'
  ],
  'AABS': [
    'FIN'
  ],
  'CALL': [
    'HOLD',
    'FIN',
  ],
  'CCL': [],
  'UCCL': [],
  'ACCL': [],
  'FIN': [],
};

Constants.COLOR = {
  1: 'info',
  2: 'success',
  
  BUS: 'info',
  TRAIN: 'success',　　
};

Constants.MAX_PRE = 4;

Constants.CALL_TABLE_COLUMN = 5;

Constants.SHOP_IMAGE_DIR = '/images/shop/';

export default Constants;