import React, { useEffect } from 'react';
import { generatePath } from 'react-router';

const DisplayLinker = (props) => {

  useEffect(() => {
    window.open(`${generatePath(`${props.match.path}`, { shopId: props.match.params.shopId })}`);
    window.open(`${generatePath(`${props.match.path}`, { shopId: props.match.params.shopId })}signage`);
    //window.open(`${generatePath(`${props.match.path}`, { shopId: props.match.params.shopId })}smartsignage`);
  }, [props.match.params.shopId, props.match.path]);

  return (
    <div className="container">
    </div>
  )
}

export default DisplayLinker;