import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';

const ShopEdit = (props) => {
  const { shop } = props;
  const [lockData, setLockData] = useState(false);
  const [shopData, setShopData] = useState(null);
  const { addToast } = useToasts();

  const refreshShop = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const getShop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/${props.match.params.shopId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    const temp = {
      shop_id: getShop.shop_id,
      shop_name: getShop.shop_name || "",
      shop_detail: getShop.shop_detail || "",
      shop_tel: getShop.shop_tel || "",
      shop_image_url: getShop.shop_image_url,
      upd_name: getShop.upd_name,
      upd_date: getShop.upd_date,
      // pro=本番環境・審査環境の時はホスト名を付けない、test=heroku開発環境・dev=社内の開発環境の時はAWSのfrontendのホスト名を付ける
      uploadfile: getShop.shop_image_url ? (process.env.REACT_APP_ENV === 'pro' ? '' : process.env.REACT_APP_AWS_FRONTEND_URL) + constClass.SHOP_IMAGE_DIR + getShop.shop_image_url : null,
      uploadfiletype: null,
      deletefile: null
    };
    setShopData(temp);
  },[props.match.params.shopId])

  // 単一レコード　val値change
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setShopData({ ...shopData, [name]: value });
  }

  // 画像ファイル
  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setShopData({
        ...shopData,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: shopData.shop_image_url
      });
    }
    reader.readAsDataURL(value);
  }

  // 画像ファイル削除
  const handleDeleteFile = () => {
    setShopData({ ...shopData, uploadfile: null, uploadfiletype: null, deletefile: shopData.shop_image_url });
  }

  //更新ボタン押下時
  const handleUpdateClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (!data.shop_name) {
      window.alert('店舗名を入力してください。');
      return;
    }

    if (data.shop_name.length > 100) {
      window.alert('店舗名は100字以内で入力してください。');
      return;
    }
    if (data.shop_detail.length > 500) {
      window.alert('店舗詳細は500字以内で入力してください。');
      return;
    }
    if (data.shop_tel.length > 20) {
      window.alert('電話番号は20字以内で入力してください。');
      return;
    }
    // if (data.shop_image_url.length > 100) {
    //   window.alert('店舗画像URLは100字以内で入力してください。');
    //   return;
    // }
    
    // 確認メッセージ
    if (!window.confirm(`店舗マスタ[${data.shop_name}:${data.shop_detail}]を更新します。よろしいですか？`)) {
      return;
    }

    // const params = {
    //   shop_id: data.shop_id,
    //   shop_name: data.shop_name || "",
    //   shop_detail: data.shop_detail || "",
    //   shop_tel: data.shop_tel || "",
    //   shop_image_url: data.shop_image_url || "",
    //   upd_name: data.upd_name,
    //   upd_date: data.upd_date,
    // };
    const params = data;
    if (params.uploadfiletype === null) params.uploadfile = null;
    console.log(params);
    let res;
    try {
      setLockData(true);
      res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/shop/update/${props.match.params.shopId}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });

      } else{
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshShop();
      }
      setLockData(false);
    }
  }
  
  const UpdateButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-5`}
        onClick={() => handleUpdateClick(data)}>
        更新
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshShop();
    }
    return fetchData();
  }, [refreshShop]);

  return (

    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(shop)}</div></div>
      {shopData !== null && (

        <div className="row mb-3 col-10" >

          {/* 店舗名 */}
         <label htmlFor="shop_name" className="col-sm-3 col-form-label my-sm-3">店舗名</label>
          <div className="col-sm-9 my-sm-3">
           <input type="text" className="form-control" id="shop_name" name="shop_name" value={shopData.shop_name} onChange={handleChange}></input>
         </div>

          {/* 店舗詳細 */}
          <label htmlFor="shop_detail" className="col-sm-3 col-form-label my-sm-3">店舗詳細</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id="shop_detail" name="shop_detail" value={shopData.shop_detail} onChange={handleChange}></input>
          </div>
          
          {/* 店舗電話番号 */}
          <label htmlFor="shop_tel" className="col-sm-3 col-form-label my-sm-3">店舗電話番号</label>
          <div className="col-sm-9 a my-sm-3 ">
          <input type="text" className="form-control" id="shop_tel" name="shop_tel" value={shopData.shop_tel} onChange={handleChange}></input>
          </div>

          {/* 店舗画像 */}
          <label htmlFor="shop_image_url" className="col-sm-3 col-form-label my-sm-3">店舗画像</label>
          <div className="col-sm-9 my-sm-3">
            {!(shopData.uploadfile) && <input type="file" className="form-control-file" name="productImage" id="productImage" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {shopData.uploadfile && <object id="uploadfileview" data={shopData.uploadfile} type={shopData.uploadfiletype}><img src={shopData.uploadfile} alt='uploadfile' /></object>}
            {shopData.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>

          {/* 更新ボタン */}
          <div className="col-sm-9 my-sm-5 text-right">
            {UpdateButton(shopData)}
          </div>
        </div>

      )}
    </div>

  )
}

export default ShopEdit