import React from 'react';

const ControlDate = (props) => {

  const fontText = props.target === "sp" ? "" : "font-h3";
  const fontLabel = props.target === "sp" ? "" : "font-h3";

  const setYear = () => {
    const items = [];
    const nowYear = new Date().getFullYear();
    var range_from = props.entryData.range_from ? props.entryData.range_from : nowYear;
    var range_to = props.entryData.range_to ? props.entryData.range_to : nowYear;
    items.push(<option value="" key={"-"}>- 年 -</option>);
    for (let i = range_from ; i <= range_to; i++) {
      items.push(<option value={i} key={i}>{i}</option>);
    }
    return items;
  };
  const setMonth = () => {
    const items = [];
    items.push(<option value="" key={"-"}>- 月 -</option>)
    for (let i = 1; i <= 12; i++) {
      items.push(<option value={("00" + i).slice(-2)} key={i}>{i}</option>)
    }
    return items;
  }
  const setDay = () => {
    const items = [];
    items.push(<option value="" key={"-"}>- 日 -</option>)
    for (let i = 1; i <= 31; i++) {
      items.push(<option value={("00" + i).slice(-2)} key={i}>{i}</option>)
    }
    return items;
  }

  const handleDateChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name.split('_')[0];
    const idx = target.name.split('_')[1];
    let temp = props.value || "//";
    let parts = temp.split("/");
    parts[idx] = value;
    props.setState(name, parts.join("/"));
  }
  
  
  return (
    <div className="row text-center bg-lightwhite w-100 p-1 ml-1">
      {props.entryData.entry_name && <label className={`w-25 ${fontLabel}`}>{props.entryData.entry_name}</label>}
      <div className={props.entryData.entry_name ? `w-25` : `w-33`}>
      <select
        disabled={props.disabled}
        className={`w-75 h-100 ${fontText}`}
        id={props.entryData.param_name + "_0"}
        name={props.entryData.param_name + "_0"}
        value={props.value ? props.value.split('/')[0] : ""}
        onChange={(e) => { handleDateChange(e) }}>
          {setYear()}
      </select>
      <label className={`w-25 ${fontLabel}`}>年</label>
      </div>
      <div className={props.entryData.entry_name ? `w-25` : `w-33`}>
      <select
        disabled={props.disabled}
        className={`w-75 h-100 ${fontText}`}
        id={props.entryData.param_name + "_1"}
        name={props.entryData.param_name + "_1"}
        value={props.value ? props.value.split('/')[1] : ""}
        onChange={(e) => { handleDateChange(e) }}>
          {setMonth()}
      </select>
      <label className={`w-25 ${fontLabel}`}>月</label>
      </div>
      <div className={props.entryData.entry_name ? `w-25` : `w-33`}>
      <select
        disabled={props.disabled}
        className={`w-75 h-100 ${fontText}`}
        id={props.entryData.param_name + "_2"}
        name={props.entryData.param_name + "_2"}
        value={props.value ? props.value.split('/')[2] : ""}
        onChange={(e) => { handleDateChange(e) }}>
          {setDay()}
      </select>
      <label className={`w-25 ${fontLabel}`}>日</label>
      </div>
    </div>
  )

}

export default ControlDate;
