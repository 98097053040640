import React from 'react';
import ControlNumber from '../Control/ControlNumber';
import ControlToggle from '../Control/ControlToggle';
import ControlText from '../Control/ControlText';
import ControlRadio from '../Control/ControlRadio';
import ControlDate from '../Control/ControlDate';
import ControlCheck from '../Control/ControlCheck';
import ControlLabel from '../Control/ControlLabel';
import ControlSelect from '../Control/ControlSelect';

const Control = (props) => {

  /**
   * コントロール生成
   */
  if (props.entryData.input_type === 'number') return <ControlNumber {...props} key={props.entryDataIdx} />
  else if (props.entryData.input_type === 'toggle') return <ControlToggle {...props} key={props.entryDataIdx} />
  else if (props.entryData.input_type === 'text') return <ControlText {...props} key={props.entryDataIdx} />
  else if (props.entryData.input_type === 'radio') return <ControlRadio {...props} key={props.entryDataIdx} />
  else if (props.entryData.input_type === 'date') return <ControlDate {...props} key={props.entryDataIdx} />
  else if (props.entryData.input_type === 'check') return <ControlCheck {...props} key={props.entryDataIdx} />
  else if (props.entryData.input_type === 'label') return <ControlLabel {...props} key={props.entryDataIdx} />
  else if (props.entryData.input_type === 'select' && props.entryDataIdx === 0) return <ControlSelect {...props} key={props.entryDataIdx} />
  else return '';

}

export default Control;






