import React from 'react';

const ControlSelect = (props) => {

  const fontText = props.target === "sp" ? "" : "font-h3";

  return (
    <div className="row w-100 mx-0 px-3-env line-height-2-2">
    <select
        disabled={props.disabled}
        className={`w-100 h-100 py-2 ${fontText}`}
        id={props.entryData.param_name}
        name={props.entryData.param_name}
        value={props.value ? props.value : ""}
        onChange={(e) => { props.setState(props.entryData.param_name, e.target.value) }}>
        <option value="">- 選択 -</option>
        {props.m_entry_data.map((data, idx) => (
          <option value={data.entry_data} key={idx} >{data.entry_name}</option>
        ))}
      </select>
    </div>
  )

}

export default ControlSelect;
