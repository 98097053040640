import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import util from 'util';
import { useParams } from 'react-router-dom';
import sound from '../Sound/Notify';
import voice from '../Sound/Voice';
import Common from '../Common/common.js';
import { useToasts } from 'react-toast-notifications';

const SmartSignage = (props) => {
  const { settings } = props;
  const { useVoice } = useParams();
  const [wait_count, setWaitCount] = useState(null);
  const [call_data, setCallData] = useState(null);
  const [call_window_data, setCallWindowData] = useState(null);
  const [abs_data, setAbsData] = useState(null);
  const [highlight, setHighlight] = useState([]);
  const [count_order, setCountOrder] = useState('');
  const { addToast } = useToasts();
  var previous_call_data_ref = useRef(null);

  const refreshOrderCount = useCallback(async () => {
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/${props.match.params.shopId}`)).data;
    const voice_use_flg = settings.find(s => s.setting_type === constClass.SETTING.VOICE_USE_FLG) ? Number(settings.find(s => s.setting_type === constClass.SETTING.VOICE_USE_FLG).setting_val) : 0;
    const pitch = settings.find(s => s.setting_type === constClass.SETTING.VOICE_PITCH) ? Number(settings.find(s => s.setting_type === constClass.SETTING.VOICE_PITCH).setting_val) : 1;
    const rate = settings.find(s => s.setting_type === constClass.SETTING.VOICE_RATE) ? Number(settings.find(s => s.setting_type === constClass.SETTING.VOICE_RATE).setting_val) : 1;
    const previous_call_data = previous_call_data_ref.current;
    // sound play
    if (data.call_data && previous_call_data && useVoice === "voice") {
      var highlight_data = data.call_data.map((data, idx) => ((data.receipt_num && !previous_call_data.includes(data.receipt_num)) ? data.receipt_num : undefined)).filter(v => v);
      setHighlight(highlight_data);
      if (highlight_data.length > 0) {
        sound.play().catch( error => addToast('音声が流れない場合は画面をクリックしてください。', { appearance: 'success', autoDismiss: true }) );
      }
      if (voice_use_flg) {
        highlight_data.forEach(d => {
          var text = Common.getSettingValue(settings, 'CALL_WINDOW_FUNCTION') === '1' 
                      ? `お待たせしました。${d}番のかた、${data.call_window_data.find(c => c.receipt_num === d).user_short}へお越しください` 
                      : `お待たせしました。${d}番のかた、受付へお越しください`;
          voice.play(text, pitch, rate);
        });
      }
    }
    setWaitCount(data.wait_count);
    setCountOrder(data.wait_count !== null && data.wait_count.length > 0 ? data.wait_count[0].count_order : 0);

    var check_blank;
    const call_limit = Number(Common.getSettingValue(settings, 'LIMIT_CALL'));
    const abs_limit = Number(Common.getSettingValue(settings, 'LIMIT_ABS'));

    // 呼出中(CALL)
    check_blank = new Array(call_limit - (data.call_data ? Math.min(data.call_data.length, call_limit) : 0)).fill({});
    setCallData(data.call_data.slice(0, call_limit).concat(check_blank));

    setCallWindowData(data.call_window_data);

    // 不在(ABS)
    check_blank = new Array(abs_limit - (data.abs_data ? Math.min(data.abs_data.length, abs_limit) : 0)).fill({});
    setAbsData(data.abs_data.slice(0, abs_limit).concat(check_blank));

    previous_call_data_ref.current = data.call_data.map(d => d.receipt_num);
  },[props.match.params.shopId, settings, useVoice]);

  useEffect(() => {
    var intervalId;
    async function fetchData() {
      await refreshOrderCount();
      intervalId = setInterval(() => {
        refreshOrderCount();
      }, 5000);
    }
    if (settings) fetchData();
    return () => {
      clearInterval(intervalId);
    };
}, [settings, refreshOrderCount]);

  //単一窓口
  const renderCall = () => {
    //表示列数を定義
    const col = constClass.CALL_TABLE_COLUMN;
    //最大呼び出し可能人数
    const limit = Number(Common.getSettingValue(settings, 'LIMIT_CALL'));

    return (
      <tbody className="bg-white">
        {call_data !== null && call_data.map((data, idx) => (
          idx % col === 0 ? (
            <tr key={idx}>
              <td className="w-20">
                <h3 className={`mb-0 ${highlight.includes(data.receipt_num) ? "blink" : ""}`}>
                  {data.receipt_num ? data.receipt_num : '-'}
                </h3>
              </td>
              <td className={`w-20 ${idx + 1 >= limit ? "bg-gray" : ""}`}>
                <h3 className={`mb-0 ${call_data[idx + 1] && highlight.includes(call_data[idx + 1].receipt_num) ? "blink" : ""}`}>
                  {call_data[idx + 1] && call_data[idx + 1].receipt_num ? call_data[idx + 1].receipt_num : '-'}
                </h3>
              </td>
              <td className={`w-20 ${idx + 2 >= limit ? "bg-gray" : ""}`}>
                <h3 className={`mb-0 ${call_data[idx + 2] && highlight.includes(call_data[idx + 2].receipt_num) ? "blink" : ""}`}>
                  {call_data[idx + 2] && call_data[idx + 2].receipt_num ? call_data[idx + 2].receipt_num : '-'}
                </h3>
              </td>
              <td className={`w-20 ${idx + 3 >= limit ? "bg-gray" : ""}`}>
                <h3 className={`mb-0 ${call_data[idx + 3] && highlight.includes(call_data[idx + 3].receipt_num) ? "blink" : ""}`}>
                  {call_data[idx + 3] && call_data[idx + 3].receipt_num ? call_data[idx + 3].receipt_num : '-'}
                </h3>
              </td>
              <td className={`w-20 ${idx + 4 >= limit ? "bg-gray" : ""}`}>
                <h3 className={`mb-0 ${call_data[idx + 4] && highlight.includes(call_data[idx + 4].receipt_num) ? "blink" : ""}`}>
                  {call_data[idx + 4] && call_data[idx + 4].receipt_num ? call_data[idx + 4].receipt_num : '-'}
                </h3>
              </td>
            </tr>
          ) : null
        ))}
      </tbody>
    )
  }

  //窓口別呼出し
  const renderCallWindow = () => {
    return (
      <tbody className="bg-white">
        {call_window_data !== null && call_window_data.map((data, idx) => (
          idx % 2 === 0 ? (
            <tr className="" key={data.user_id}>
              <td className="sm_td_item">
                <h3 className="mb-0">
                  {data.user_short ? data.user_short : '-'}
                </h3>
              </td>
              <td className="sm_td_num">
                <h3 className={`mb-0 ${highlight.includes(data.receipt_num) ? "blink" : ""}`}>
                  {data.receipt_num ? data.receipt_num : '-'}
                </h3>
              </td>
              {(idx + 1) < call_window_data.length &&
                <td className="sm_td_item">
                  <h3 className="mb-0">
                    {call_window_data[idx + 1].user_short ? call_window_data[idx + 1].user_short : '-'}
                  </h3>
                </td>
              }
              {(idx + 1) < call_window_data.length &&
                <td className="sm_td_num">
                  <h3 className={`mb-0 ${highlight.includes(call_window_data[idx + 1].receipt_num) ? "blink" : ""}`}>
                    {call_window_data[idx + 1].receipt_num ? call_window_data[idx + 1].receipt_num : '-'}
                  </h3>
                </td>
              }
            </tr>
          ) : null
        ))}
      </tbody>
    )
  }

  //表示列数を定義
  const col = constClass.CALL_TABLE_COLUMN;
  //最大不在表示人数
  const abs_limit = Number(Common.getSettingValue(settings, 'LIMIT_ABS'));

  return (
    <div className="container-fluid text-center">
      <div className="row px-2 pt-3">
        {wait_count !== null && (
          <div className="col-12">
            <h3 className="waiting">現在     {count_order}     {settings && settings.find(s => s.setting_type === 'LABEL_WAIT_COUNT_UNIT').setting_val}待ち</h3>
          </div>
        )}
      </div>
      <div className="row px-2">
        <div className="d-none">{util.inspect(call_data)}</div>
        <div className="col-12">
          <table className="table table-call mb-1">
            <thead className="bg-call-head">
              <tr>
                <td colSpan="5">
                  <h5 className="mb-0 call-head">{settings && settings.find(s => s.setting_type === 'LABEL_CALL_NUMBER').setting_val}</h5>
                </td>
              </tr>
            </thead>
            {Common.getSettingValue(settings, 'CALL_WINDOW_FUNCTION') === '1' && renderCallWindow()}
            {Common.getSettingValue(settings, 'CALL_WINDOW_FUNCTION') === '0' && renderCall()}
          </table>
        </div>
      </div>
      <div className="row px-2">
        <div className="col-12">
          <table className="table table-absence mb-1">
            <thead className="bg-absence-head">
              <tr>
                <td colSpan="5">
                  <h5 className="mb-0 absence-head">{settings && settings.find(s => s.setting_type === 'LABEL_ABSENCE_NUMBER').setting_val}</h5>
                </td>
              </tr>
            </thead>
            <tbody className="bg-absence">
              {abs_data !== null && abs_data.map((data, idx) => (
                idx % col === 0 ? (
                  <tr key={idx}>
                    <td className="w-20">
                      <h3 className={`mb-0`}>
                        {data.receipt_num ? data.receipt_num : '-'}
                      </h3>
                    </td>
                    <td className={`w-20 ${idx + 1 >= abs_limit ? "bg-gray" : ""}`}>
                      <h3 className={`mb-0`}>
                        {abs_data[idx + 1] && abs_data[idx + 1].receipt_num ? abs_data[idx + 1].receipt_num : '-'}
                      </h3>
                    </td>
                    <td className={`w-20 ${idx + 2 >= abs_limit ? "bg-gray" : ""}`}>
                      <h3 className={`mb-0`}>
                        {abs_data[idx + 2] && abs_data[idx + 2].receipt_num ? abs_data[idx + 2].receipt_num : '-'}
                      </h3>
                    </td>                    
                    <td className={`w-20 ${idx + 3 >= abs_limit ? "bg-gray" : ""}`}>
                      <h3 className={`mb-0`}>
                        {abs_data[idx + 3] && abs_data[idx + 3].receipt_num ? abs_data[idx + 3].receipt_num : '-'}
                      </h3>
                    </td>                    
                    <td className={`w-20 ${idx + 4 >= abs_limit ? "bg-gray" : ""}`}>
                      <h3 className={`mb-0`}>
                        {abs_data[idx + 4] && abs_data[idx + 4].receipt_num ? abs_data[idx + 4].receipt_num : '-'}
                      </h3>
                    </td>
                  </tr>
                ) : null
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row p-2 pt-1">
        <div className="col text-danger">
          <h5 className="message">{settings && settings.find(s => s.setting_type === 'MESSAGE_SIGNAGE').setting_val}</h5>
        </div>
      </div>
    </div>
  )
}

export default SmartSignage;