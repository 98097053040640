import React from 'react';

const ControlLabel = (props) => {

  const fontLabel = props.target === "sp" ? "w-75" : "font-h3 w-85";

  return (
    <div className="text-center bg-lightwhite w-100 ml-1">
      <div className={`m-auto ${fontLabel}`}>{props.entryData.entry_name}</div>
    </div>
  )

}

export default ControlLabel;