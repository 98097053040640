import React, { PureComponent } from 'react';
// import { Link } from 'react-router-dom';
// import util from 'util';
import moment from 'moment';
import QRCode from "qrcode.react";

class Ticket extends PureComponent {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    return (
      <div className="print-body text-black">
        {this.props.order !== null && (
          <div>
            <div className="row border-bottom border-black mx-0">
              {/* <div className="col-12">
                <div className="mb-2 text-center text-black">
                  <h2>整理券</h2>
                </div>
              </div> */}
              <div className="col-12">
                <div className="mb-2 text-center text-black">
                  <h3>{this.props.settings && this.props.settings.find(s => s.setting_type === 'PRINT_TITLE_NAME').setting_val}</h3>
                </div>
              </div>
            </div>
            <div className="row border-bottom border-black mb-2 mx-0">
              <div className="col-12 text-center text-black align-self-center">
                <h4 className="mt-2 mb-0 ">受付番号</h4>
              </div>
              <div className="col-12 text-center text-black align-self-center">
                <h1 className="mt-1 font-weight-bold">{this.props.order.receipt_num} 番</h1>
              </div>
              {/*this.props.order[constClass.COLUMN.CLASS] === constClass.CLASS.BUS &&
                <div className="col-12 mb-2 text-center text-black align-self-center">
                  <QRCode value={`${process.env.REACT_APP_LINE_FRIEND_BUS_TICKET}`} />
                </div>
              }
              {this.props.order[constClass.COLUMN.CLASS] === constClass.CLASS.TRAIN &&
                <div className="col-12 mb-2 text-center text-black align-self-center">
                  <QRCode value={`${process.env.REACT_APP_LINE_FRIEND_TRAIN_TICKET}`} />
                </div>
              }
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className="font-weight-bold">
                  QRコードから<br />
                  LINEを友だち追加すると<br />
                  呼出し状況を確認可能です
                </h5>
            </div>*/}
            </div>
            <div className="row border-bottom border-black mb-2 mx-0">
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className=" ">
                  {this.props.settings && this.props.settings.find(s => s.setting_type === 'LINE_MESSAGE_REGIST').setting_val
                  /*
                  お呼出しから{this.props.settings && this.props.settings.find(s => s.setting_type === 'INTERVAL_ABS').setting_val}分以内で<br />
                  入店されませんと不在と<br />
                  なり{this.props.settings && Number(this.props.settings.find(s => s.setting_type === 'INTERVAL_ABS').setting_val) + Number(this.props.settings.find(s => s.setting_type === 'INTERVAL_CCL').setting_val)}分で順番待ち取り<br />
                  消しとなります。
                  */}
                </h5>
              </div>
            </div>
            <div className="row mb-2 mx-0">
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className=" ">
                  お呼出し状況はこちら
                </h5>
              </div>
              </div>
              <div className="row border-bottom border-black mb-2 mx-0">
                <div className="col-12 mb-2 text-center text-black align-self-center">
                  <QRCode value={`${this.props.path}`} />
                </div>
              </div>          
            {/* <div className="row border-bottom border-black mb-2 mx-0">
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className="">
                  お呼出しの順番は<br />
                  前後する可能性が<br />
                  ございます。
                </h5>
              </div>
            </div> */}
            <div className="row mb-2 pb-2 mx-0">
              <div className="col-12 text-black">
                {/* <h4 className="">{constClass.PAYMENT_NAME[this.props.order[constClass.COLUMN.PAYMENT]]} | {constClass.CLASS_NAME[this.props.order[constClass.COLUMN.CLASS]]} | {constClass.METHOD_NAME[this.props.order[constClass.COLUMN.METHOD]]}</h4> */}
                {/* m_entry_dataが存在しない場合またはinput_typeがlabelでない場合表示する */}
                {this.props.entry_type && this.props.entry_type.filter(data => data.m_entry_data.length === 0 || data.m_entry_data[0].input_type !== "label").map((data, idx) => (
                  <div className="row mt-0 mx-0 font-h5" key={idx}>
                    <div className="col m-0 p-0 text-right">
                    {data.entry_type_short_name || data.entry_type_name}:
                    </div>
                    <div className="col m-0 p-0 pl-1 text-left">
                    {this.props.order && this.props.order.t_entry.filter(d => d.entry_type === data.entry_type).map((entry, entryIdx) => (entry.entry_name)).join(' ')}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-12 text-center text-black align-self-center font-h5">
                {moment(this.props.order.ins_date).format('YYYY/MM/DD HH:mm:ss')}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Ticket;


