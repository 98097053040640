exports.getSettingValue = function (settings, setting_type, default_value = null) {
    return settings && settings.find(s => s.setting_type === setting_type) && settings.find(s => s.setting_type === setting_type).setting_val ? settings.find(s => s.setting_type === setting_type).setting_val : default_value;
}

exports.getTimeString = function (time) {
    return `${Math.floor(Number(time) / 100)}:${time.substr(2,2)}`;
}

// 契約期間内かどうか
exports.checkContractPeriod = function (settings) {
    const start = this.getSettingValue(settings, "CONTRACT_PERIOD_START", "00010101");
    const end = this.getSettingValue(settings, "CONTRACT_PERIOD_END", "99991231");
    const today = new Date();
    const date = String(today.getFullYear()) + (('00' + String(today.getMonth() + 1)).slice(-2)) + (('00' + String(today.getDate())).slice(-2));
    if (start <= date && date <= end) {
      return true;
    }
    return false;
  }


/****************************************************************
 * 機　能： 入力された値が日付でYYYY/MM/DD形式になっているか調べる
 * 引　数： strDate = 入力された値
 * 戻り値： 正 = true　不正 = false
 ****************************************************************/
exports.checkDate = function(strDate) {
    if(strDate !== null){  
        if(!strDate.match(/^\d{4}\/\d{2}\/\d{2}$/)){
            return false;
        }
        var y = strDate.split("/")[0];
        var m = strDate.split("/")[1] - 1;
        var d = strDate.split("/")[2];
        var date = new Date(y,m,d);
        if(date.getFullYear() !== Number(y) || date.getMonth() !== Number(m) || date.getDate() !== Number(d)){
            return false;
        }
    }
    return true;
}

