import React from 'react';

const ControlToggle = (props) => {

  const fontButton = props.target === "sp" ? "" : "font-h3";

  return (
    <div className="text-center bg-lightwhite w-50 p-1 mb-3">
      <button
        disabled={props.disabled}
        className={`btn ${props.value === props.entryData.entry_data ? "btn-active" : "btn-enable"} w-100 h-100 p-1 ${fontButton}`}
        onClick={() => { props.setState(props.entryData.param_name, props.entryData.entry_data) }}>
        {props.entryData.entry_name}
      </button>
    </div>
  )

}

export default ControlToggle;
