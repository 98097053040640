import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import ArrowOn from '../Images/arrow_on.png';
import ArrowOff from '../Images/arrow_off.png';
import Modal from 'react-modal';
import Control from '../Control/Control';
import Common from '../Common/common.js';

Modal.setAppElement("#root");

class Order extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      my_order: null,
      payment: null,
      class_id: null,
      method: null,
      message: null,
      open_data: null,
      wait_count: null,
      call_data: null,
      call_window_data: null,
      pre_data: null,
      ready: false,
      shopId: isFinite(props.match.params.shopId) ? props.match.params.shopId : null,
      entry_type: [],
      not_line: false,
      sleeping: false,
      shops: null,
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '50%',
        bottom: 'auto',
        marginTop: '-25%',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };

    this.refreshOrder = this.refreshOrder.bind(this);
    //this.change = this.change.bind(this);
    this.handleRequestClick = this.handleRequestClick.bind(this);
    this.setReady = this.setReady.bind(this);
    this.getShopList = this.getShopList.bind(this);
  }

  async componentDidMount() {

    if (this.state.shopId) {
      clearInterval(this.intervalCount);
      this.intervalCount = setInterval(() => {
        this.refreshOrderCount();
      }, 10000);

      try {
        const reg_params = {
          "operator": "and",
          "where": [{ "shop_id": this.state.shopId }]
        }
        this.settings = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`, reg_params)).data;
        this.meta = {
          link: {
            rel: {
              icon: `/${this.state.shopId}/favicon.ico`,
              "apple-touch-icon": `/${this.state.shopId}/logo192.png`,
              manifest: `/${this.state.shopId}/manifest.json`,
            },
          },
          title: Common.getSettingValue(this.settings, 'TITLE_NAME', ''),
          description: Common.getSettingValue(this.settings, 'TITLE_NAME', ''),
        };
        // ロゴ画像ファイル
        const logo_file = Common.getSettingValue(this.settings, 'LOGO_FILE', '');
        if (logo_file) {
          this.logo = require(`../Images/${logo_file}`).default;
        }
        this.getEntryType();
        this.refreshOrder();
        this.refreshOrderCount();
        this.disableBounceScroll();
        this.setState({ sleeping: false });
      } catch (err) {
        this.setState({ sleeping: true });
      }
    }
    else {
      this.getShopList();
    }
  }

  async componentWillUnmount() {
    clearInterval(this.intervalCount);
  }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        el = el.parentElement;
      }

      if (noScroll) {
        e.preventDefault();
      }

      touchY = moveY;
    }, { passive: false });
  }

  /*
  change(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  */

  async getShopList() {
    //liff.init()の1次リダイレクト対策
    if (!this.props.liff_access_token) {
      if (!this.intervalShops) {
        this.intervalShops = setInterval(() => {
          this.getShopList();
        }, 500);
      }
      //liff.init()の2次リダイレクト時
    } else {
      clearInterval(this.intervalShops);

      try {
        //契約期間中のショップ情報を取得
        var shops = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/operation/`, {line_id: this.props.liff_access_token})).data;
      } catch (err) {
        console.log(err);
        this.setState({ not_line: true });
      }
      if(shops !== undefined){
        Object.keys(shops).forEach((idx) => {
          try {
            // pro=本番環境・審査環境の時はホスト名を付けない、test=heroku開発環境・dev=社内の開発環境の時はAWSのfrontendのホスト名を付ける
            // 設定されていない場合は、デフォルトの画像を設定する。
            shops[idx].shop_image_url = shops[idx].shop_image_url ? (process.env.REACT_APP_ENV === 'pro' ? '' : process.env.REACT_APP_AWS_FRONTEND_URL) + constClass.SHOP_IMAGE_DIR + shops[idx].shop_image_url : require(`../Images/logo.png`).default;
          } catch (err) {
            shops[idx].shop_image_url = require(`../Images/logo.png`).default;
          }
        });
      }
    }
    this.setState({ shops });
  }

  async refreshOrder() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalOrder) {
        this.intervalOrder = setInterval(() => {
          this.refreshOrder();
        }, 500);
      }
    } else {
      clearInterval(this.intervalOrder);
      const reg_params = {
        shop_id: this.state.shopId,
        line_id: this.props.liff_access_token
      }
      try {
        const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/line`, reg_params)).data;
        this.setState({
          my_order: my_order.find(o => o.line_id === this.props.liff_user_id),
          disabled: false,
          not_line: false
        });
      } catch (err) {
        console.log(err);
        this.setState({
          not_line: true
        })
      }

      var page;
      if (this.state.my_order === undefined) { // 未受付
        page = constClass.REQUEST;
      } else if (this.props.page === constClass.CANCEL) { // 受付済かつキャンセル
        page = this.props.page;
      } else { // 受付済かつキャンセル以外
        page = null;
      }
      this.props.setPage(page);
      if (this.props.page === constClass.CANCEL) {
        this.submitCancel('CCL');
      }
      this.setReady();
    }
  }

  async refreshOrderCount() {
    if (this.state.sleeping) {
      this.componentDidMount();
    }
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/${this.state.shopId}`)).data;
    // var bus_pre = data.pre_data.filter(p => p.class === constClass.CLASS.BUS);
    // var bus_blank = new Array(10 - (bus_pre ? bus_pre.length : 0)).fill({});
    // var train_pre = data.pre_data.filter(p => p.class === constClass.CLASS.TRAIN);
    // var train_blank = new Array(10 - (train_pre ? train_pre.length : 0)).fill({});
    var abs_data;
    //LIMIT_ABSに収まる[X * CALL_TABLE_COLUMN]分、空の配列を作成
    const abs_limit =  Math.ceil(Number(Common.getSettingValue(this.settings, 'LIMIT_ABS')) / constClass.CALL_TABLE_COLUMN) * constClass.CALL_TABLE_COLUMN;
    if (data.abs_data && data.abs_data.length <= abs_limit) {
      var abs_blank = new Array(abs_limit - (data.abs_data ? data.abs_data.length : 0)).fill({});
      abs_data = data.abs_data.concat(abs_blank);
    } else {
      abs_data = data.abs_data.filter((dat, idx) => idx < abs_limit);
    }
    var call_data;
    //単一窓口
    //LIMIT_CALLに収まる[X * CALL_TABLE_COLUMN]分、空の配列を作成
    var call_table_cell = Math.ceil(Number(Common.getSettingValue(this.settings, 'LIMIT_CALL')) / constClass.CALL_TABLE_COLUMN) * constClass.CALL_TABLE_COLUMN;
    if (data.call_data && data.call_data.length <= call_table_cell) {
      var call_blank = new Array(call_table_cell - (data.call_data ? data.call_data.length : 0)).fill({});
      call_data = data.call_data.concat(call_blank);
    } else {
      call_data = data.call_data.filter((dat, idx) => idx < call_table_cell);
    }

    this.setState({
      wait_count: data.wait_count,
      call_data: call_data,
      call_window_data: data.call_window_data,
      abs_data: abs_data,
      open_data: data.open_data
    });
    this.setReady();
  }

  async getEntryType() {
    const reg_params = {
      "operator": "and",
      "where": [
        { "shop_id": this.state.shopId },
      ]
    }
    const entry_type = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/`, reg_params)).data;
    entry_type.sort((a, b) => a.entry_type > b.entry_type ? 1 : -1);
    entry_type.map((d, idx) => (
      d.m_entry_data.sort((a, b) => a.entry_seq - b.entry_seq)
    ));

    this.setState({
      entry_type: entry_type,
    });
    entry_type.map((data, idx) => (
      data.m_entry_data.map((entryData, entryDataIdx) => (
        this.setState({ [entryData.param_name]: entryData.default_value })
      ))
    ));
  }

  setReady() {
    if (this.settings.length > 0 &&
      this.state.my_order !== null &&
      this.state.wait_count !== null &&
      this.state.call_data !== null &&
      this.state.call_window_data !== null &&
      this.state.abs_date !== null &&
      this.state.open_data !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }

  submitPayment(payment) {
    this.setState({ payment, message: null });
  }

  submitClass(class_id) {
    this.setState({ class_id, message: null });
  }

  submitMethod(method) {
    this.setState({ method, message: null });
  }

  checkDataInvalid() {
    let ret = false;
    this.state.entry_type.map((data, idx) => (
      data.m_entry_data.map((entryData, entryDataIdx) => {
        if (data.required && (this.state[entryData.param_name] === null || this.state[entryData.param_name] === "")) {
          ret = true;
        } else if (entryData.input_type === 'date' && !Common.checkDate(this.state[entryData.param_name])) {
          ret = true;
        }
        return ret
      })
    ));
    return ret;
  }

  async submit() {
    if (this.checkDataInvalid() || this.state.disabled) {
      return;
    }
    if (window.confirm(`受付番号を発行してもよろしいですか？`)) {//\r\n${constClass.PAYMENT_NAME[this.state.payment]}・${constClass.CLASS_NAME[this.state.class_id]}・${constClass.METHOD_NAME[this.state.method]}
      this.setState({ disabled: true });
      var data = {
        "shop_id": this.state.shopId,
        'line_id': this.props.liff_access_token,
      };
      var entry_data = {};
      this.state.entry_type.filter(data => data.m_entry_data.length === 0 || data.m_entry_data[0].input_type !== "label").map((data, idx) => (
        data.m_entry_data.map((entryData, entryDataIdx) => (
          entry_data[entryData.param_name] = this.state[entryData.param_name]
        ))
      ));
      data['entry_data'] = entry_data;
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data);
        this.setState({ message: `受付番号を発行しました`, adult_count: 1, child_count: 0, counter: null });
      } catch (err) {
        this.setState({ message: err });
        window.alert(err.response.data.msg);
      }
      await this.refreshOrder();
      if (window.liff.isInClient()) {
        window.liff.closeWindow();
      }
    }
  }

  async submitCancel(command = null) {
    if (this.state.my_order === null || this.state.my_order === undefined || this.state.disabled) {
      return;
    }
    if (window.confirm(`キャンセルしてもよろしいですか？\r\n受付番号：${this.state.my_order.receipt_num}番`)) {
      this.setState({ disabled: true });
      const data = {
        status: constClass.STATUS.UCCL,
        'line_id': this.props.liff_access_token,
      };
      try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/line/${this.state.my_order.order_id}`, data);
        this.setState({ message: `キャンセルしました`, adult_count: 1, child_count: 0, counter: null })
      } catch (err) {
        this.setState({ message: err });
      }
      this.props.setPage(constClass.REQUEST);
      await this.refreshOrder();
      if (window.liff.isInClient()) {
        window.liff.closeWindow();
      }
    } else {
      if (command === 'CCL') {
        if (window.liff.isInClient()) {
          window.liff.closeWindow();
        }
      }
    }
  }

  handleRequestClick() {
    this.props.setPage(constClass.REQUEST);
  }

  renderHeader() {
    return (
      <header className="header">
        <div className="row mx-0">
          <div className="col section section-top">
            <span>{this.state.shops !== null ? `順番待ち一覧` :
              this.state.my_order !== undefined ? `受付番号確認` :
                this.props.page === constClass.REQUEST ? `順番待ち` : `呼出し状況`}</span>
          </div>
        </div>
      </header>
    );
  }

  renderReceipt() {
    var text_class = `text-${constClass.COLOR.BUS}`;
    var border_class = `border-${constClass.COLOR.BUS}-bold`;
    return (
      <div className="row mx-0 my-5">
        <div className="col">
          <div className="row">
            <div className="col text-center">
              <h4>あなたの受付番号は</h4>
            </div>
          </div>
          <div className="row p-1 justify-content-center">
            <div className={`col-auto text-center ${text_class} mx-1 py-1 w-50 bg-white border ${border_class}`}>
              <h4 className="mb-0 font-weight-bold">{this.state.my_order.receipt_num}</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSection() {
    return (
      <div className="row mx-0">
        <div className="col section">
          <span>{`呼出し状況`}</span>
        </div>
      </div>
    );
  }

  renderCount() {
    return (
      <div className="row mx-0 my-3 px-3-env">
        <div className="col">
          <div className="row">
            <div className="col text-center">
              <span>{Common.getSettingValue(this.settings, 'LABEL_WAIT_COUNT')}</span>
            </div>
          </div>
          <div className="row p-1">
            <div className="col text-center ml-2 mr-1">
              <div className={`row border border-${constClass.COLOR.BUS} bg-${constClass.COLOR.BUS}`}>
                <div className="col bg-white px-0 py-1">
                  <h5 className="mb-0 d-inline font-weight-bold">{this.state.wait_count.find(x => true) ? this.state.wait_count.find(x => true).count_order : 0}</h5>
                  <span className="d-inline small">{Common.getSettingValue(this.settings, 'LABEL_WAIT_COUNT_UNIT')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAbs() {
    var text_class = `text-${constClass.COLOR.BUS}`;
    //表示列数を定義
    const col = constClass.CALL_TABLE_COLUMN;
    //最大不在表示人数
    const abs_limit = Number(Common.getSettingValue(this.settings, 'LIMIT_ABS'));
    //表示行数を定義(最大不在表示人数 / 表示列数 の切上げ)
    var row = Math.ceil(abs_limit / col);
    var id;

    // var data;
    // var icon;
    // if (class_id === constClass.CLASS.BUS) {
    //   data = this.state.pre_data.bus;
    //   icon = IconBus;
    // } else if (class_id === constClass.CLASS.TRAIN) {
    //   data = this.state.pre_data.train;
    //   icon = IconTrain;
    // }
    return (
      <div className="px-0-env">
        {/* <div className="row mx-1 my-1">
          <div className={`col text-center ${bg_class} py-1 mx-0`}>
            <span className="align-middle">不在の方</span>
          </div>
        </div> */}
        {Array(row).fill().map((valx, idx) => {
          id = col * idx;
          return(
          <div className="row mx-1 my-1" key={idx}>
            <div className={`col text-center ${text_class} px-1`} key={id}>
              <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={id}>
                <span className="mb-0">{this.state.abs_data[id].receipt_num ? this.state.abs_data[id].receipt_num : '-'}</span>
              </div>
            </div>
            <div className={`col text-center ${text_class} px-1`} key={id + 1}>
              <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 1 >= abs_limit ? "bg-gray" : ""}`} key={id + 1}>
                <span className="mb-0">{this.state.abs_data[id + 1].receipt_num ? this.state.abs_data[id + 1].receipt_num : '-'}</span>
              </div>
            </div>
            <div className={`col text-center ${text_class} px-1`} key={id + 2}>
              <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 2 >= abs_limit ? "bg-gray" : ""}`} key={id + 2}>
                <span className="mb-0">{this.state.abs_data[id + 2].receipt_num ? this.state.abs_data[id + 2].receipt_num : '-'}</span>
              </div>
            </div>
            <div className={`col text-center ${text_class} px-1`} key={id + 3}>
              <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 3 >= abs_limit ? "bg-gray" : ""}`} key={id + 3}>
                <span className="mb-0">{this.state.abs_data[id + 3].receipt_num ? this.state.abs_data[id + 3].receipt_num : '-'}</span>
              </div>
            </div>
            <div className={`col text-center ${text_class} px-1`} key={id + 4}>
              <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 4 >= abs_limit ? "bg-gray" : ""}`} key={id + 4}>
                <span className="mb-0">{this.state.abs_data[id + 4].receipt_num ? this.state.abs_data[id + 4].receipt_num : '-'}</span>
              </div>
            </div>
          </div>
          );
        })}
      </div>
    );
  }

  //単一窓口
  renderCall(class_id) {
    var text_class = `text-${constClass.COLOR.BUS}`;
    //表示列数を定義
    const col = constClass.CALL_TABLE_COLUMN;
    //最大呼び出し可能人数
    const limit = Number(Common.getSettingValue(this.settings, 'LIMIT_CALL'));
    //表示行数を定義(最大呼び出し可能人数 / 表示列数 の切上げ)
    var row = Math.ceil(limit / col);
    var id;

    return (
      <div className="px-0-env">
        {
          Array(row).fill().map((valx, idx) => {
            id = col * idx;
            return (
              <div className="row mx-1 my-1" key={idx}>
                <div className={`col text-center ${text_class} px-1`} key={id}>
                  <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={id}>
                    <span className="mb-0 ">{this.state.call_data[id].receipt_num ? this.state.call_data[id].receipt_num : '-'}</span>
                  </div>
                </div>
                <div className={`col text-center ${text_class} px-1`} key={id + 1}>
                  <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 1 >= limit ? "bg-gray" : ""}`} key={id + 1}>
                    <span className="mb-0 ">{this.state.call_data[id + 1].receipt_num ? this.state.call_data[id + 1].receipt_num : '-'}</span>
                  </div>
                </div>
                <div className={`col text-center ${text_class} px-1`} key={id + 2}>
                  <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 2 >= limit ? "bg-gray" : ""}`} key={id + 2}>
                    <span className="mb-0 ">{this.state.call_data[id + 2].receipt_num ? this.state.call_data[id + 2].receipt_num : '-'}</span>
                  </div>
                </div>
                <div className={`col text-center ${text_class} px-1`} key={id + 3}>
                  <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 3 >= limit ? "bg-gray" : ""}`} key={id + 3}>
                    <span className="mb-0 ">{this.state.call_data[id + 3].receipt_num ? this.state.call_data[id + 3].receipt_num : '-'}</span>
                  </div>
                </div>
                <div className={`col text-center ${text_class} px-1`} key={id + 4}>
                  <div className={`w-100 text-center ${text_class} m-1 border border-cell ${id + 4 >= limit ? "bg-gray" : ""}`} key={id + 4}>
                    <span className="mb-0 ">{this.state.call_data[id + 4].receipt_num ? this.state.call_data[id + 4].receipt_num : '-'}</span>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }

  //窓口別呼出し
  renderCallWindow() {
    return (
      <div className="row mx-1">
        {this.state.call_window_data.map((data) => (
          <div className="col-6 p-0 mx-0 my-1" key={data.user_id}>
            <div className="row p-0 m-0">
              <div className={`col-8 text-center text-primary py-1 px-0 align-self-center`}>
                <span>{data.user_short}</span>
              </div>
              <div className={`col-4 text-center text-primary my-1 px-0 bg-white border border-cell`}>
                <span>{data.receipt_num ? data.receipt_num : '-'}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderCounter() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col section-dark px-0">
              {/*<p className="my-0">お呼出し中の受付番号</p>*/}
              <p className="my-0">{Common.getSettingValue(this.settings, 'LABEL_CALL_NUMBER')}</p>
            </div>
            <div className="col-12 py-2 text-white bg-white">
              {Common.getSettingValue(this.settings, 'CALL_WINDOW_FUNCTION') === '1' ? this.renderCallWindow() : this.renderCall()}
            </div>
          </div>
          <div className="row mx-0">
            <div className="col section-dark px-0">
              {/*<p className="my-0">お呼出し済みでご不在のお客様</p>*/}
              <p className="my-0">{Common.getSettingValue(this.settings, 'LABEL_ABSENCE_NUMBER')}</p>
            </div>
            <div className="col-12 text-white bg-white py-2">
              {this.renderAbs()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRequest() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          {this.state.entry_type !== null && this.state.entry_type.map((data, idx) => (
            <div key={idx}>
              <div className="row mx-0">
                <div className="col section-dark py-0 line-height-2-2">
                  <span className="mb-0 d-inline-block font-weight-bold align-middle pr-1 font-h4">
                    {//丸数字１～50、51以降は51.と表記
                      idx < 20 ? String.fromCodePoint(9312 + idx) :
                        idx < 35 ? String.fromCodePoint(12881 - 20 + idx) :
                          idx < 50 ? String.fromCodePoint(12977 - 35 + idx) :
                            1 + idx + "."
                    }
                  </span>
                  <span className="d-inline-block align-middle"> {data.entry_type_name}</span>
                </div>
              </div>
              <div className="row mx-0 bg-white px-3-env pt-3 pb-4">
                {data.remarks && <label className={`w-100 ml-1 h6`}>{data.remarks}</label>}
                {data.m_entry_data !== null && data.m_entry_data.map((entryData, entryDataIdx) => (
                  <Control target={"sp"} setState={(name, value) => { this.setState({ [name]: value }); }} m_entry_data={data.m_entry_data} entryData={entryData} entryDataIdx={entryDataIdx} value={this.state[entryData.param_name]} {...this.props} key={entryDataIdx} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderBottom() {
    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100">
          <div className="row mx-0">
            {(this.state.my_order !== undefined) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col text-center p-0">
                    <button
                      disabled={this.state.disabled}
                      className="btn-lg btn-submit-cancel w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitCancel() }}>
                      順番待ちをやめる
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.state.my_order === undefined) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col text-center p-0">
                    <button
                      disabled={this.checkDataInvalid() || this.state.disabled}
                      className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                      onClick={() => { this.submit() }}>
                      申込む <img className="arrow" src={this.checkDataInvalid() || this.state.disabled ? ArrowOff : ArrowOn} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    );
  }

  renderShops() {
    return (
      this.state.not_line ?
      <div className="liff-top bg-lightwhite font-weight-bold">
        <DocumentMeta {...this.meta} />
        <div className={`page-full`}>
        </div>
        <Modal isOpen={this.state.not_line} style={this.modalStyle}>
          <div className="text-center section m-0 py-2">
            <span>LINEをご利用ください。</span>
          </div>
        </Modal>
      </div>
      :
      <div className="row mx-0">
        <div className="col px-0">
          {this.state.shops? this.state.shops.map((data, idx) =>
            <div key={idx} >
              <Link to={`/${data.shop_id}`}>
                <div className={`row mx-0 bg-white px-3-env pt-2 pb-2 ${idx % 2 ? "bg-lightgreen" : ""}`}>
                  <div className="col-3 px-0 align-self-center icon-lg">
                    <img className=""
                      src={data.shop_image_url}
                      onError={e => e.target.style.display = 'none'} alt={`サムネイル${data.shop_id}`} />
                  </div>
                  <div className="col-9 px-2 align-self-center">
                    {data.shop_detail}
                    {/*<div>現在の待ち人数：○人</div>*/}
                  </div>
                </div>
              </Link>
            </div>
          ):
          <div>
            <Modal isOpen={true} style={this.modalStyle}>
              <div className="text-center section m-0 py-2">
                <span>LINE順番待ちサービスをご利用の際はQRコードまたは、リンクからご利用ください。</span>
              </div>
            </Modal>
          </div>
          }
        </div>
      </div>
    );
  }

  render() {
    return (
      this.state.sleeping ?
        <div className="liff-top bg-lightwhite font-weight-bold">
          <Modal isOpen={this.state.sleeping} style={this.modalStyle}>
            <div className="text-center section-white m-0 py-2">
              <h4>現在サービス停止中です</h4>
            </div>
          </Modal>
        </div>
        : this.state.ready ?
          <div className="liff-top bg-lightwhite font-weight-bold">
            <DocumentMeta {...this.meta} />
            {this.renderHeader()}
            <div className={`page-${(this.state.my_order !== undefined || this.props.page === constClass.REQUEST) ? "btn" : "full"}`}>
              {this.state.my_order === undefined && this.renderCount()}
              {this.state.my_order !== undefined && this.renderReceipt()}
              {this.state.my_order !== undefined && this.renderSection()}
              {(this.state.my_order !== undefined || this.props.page !== constClass.REQUEST) && this.renderCounter()}
              {(this.state.my_order === undefined && this.props.page === constClass.REQUEST) && this.renderRequest()}
            </div>
            {(this.state.my_order !== undefined || this.props.page === constClass.REQUEST) && this.renderBottom()}
            {this.state.disabled && <Loading />}
            <Modal isOpen={this.state.my_order === undefined && this.props.page === constClass.REQUEST && this.state.open_data.open_line_flg === constClass.CLOSE} style={this.modalStyle}>
              <div className="text-center section m-0 py-2">
                <span>{Common.getSettingValue(this.settings, 'MESSAGE_NO_WAITING_1')}</span><br />
                {Common.getSettingValue(this.settings, 'MESSAGE_NO_WAITING_2')}<br />
                {/*
              {Common.getSettingValue(this.settings, 'CAL_LINE_DEFAULT_START') <= '2400' || Common.getSettingValue(this.settings, 'CAL_LINE_DEFAULT_END') <= '2400'
                ? `平日：${Common.getTimeString(Common.getSettingValue(this.settings, 'CAL_LINE_DEFAULT_START'))}～${Common.getTimeString(Common.getSettingValue(this.settings, 'CAL_LINE_DEFAULT_END'))}`
                : ''
              }<br />
              {Common.getSettingValue(this.settings, 'CAL_LINE_HOLIDAY_START') <= '2400' || Common.getSettingValue(this.settings, 'CAL_LINE_HOLIDAY_END') <= '2400'
                ? `土日：${Common.getTimeString(Common.getSettingValue(this.settings, 'CAL_LINE_HOLIDAY_START'))}～${Common.getTimeString(Common.getSettingValue(this.settings, 'CAL_LINE_HOLIDAY_END'))}`
                : ''
              }
              */}
              </div>
            </Modal>
            <Modal isOpen={this.state.my_order === undefined && this.props.page === constClass.REQUEST && this.state.open_data.open_wait_flg === constClass.CLOSE} style={this.modalStyle}>
              <div className="text-center section-white m-0 py-2">
                {/*<h4>すぐにご入店いただけます</h4>
              <span>ご来店ありがとうございます</span><br /><br />*/}
                <h4>{Common.getSettingValue(this.settings, 'MESSAGE_NO_WAITING_1')}</h4>
                <span>{Common.getSettingValue(this.settings, 'MESSAGE_NO_WAITING_2')}</span><br /><br />
                <img className="w-50" src={this.logo} onError={e => e.target.style.display = 'none'} alt="ロゴ画像" />
              </div>
            </Modal>
            <Modal isOpen={Common.checkContractPeriod(this.settings) === false} style={this.modalStyle}>
              <div className="text-center section-white m-0 py-2">
                <h4>サービス契約期間外です</h4>
              </div>
            </Modal>
            <Modal isOpen={(this.state.wait_count.find(x => true) ? this.state.wait_count.find(x => true).count_order : 0) >= Number(Common.getSettingValue(this.settings, 'LIMIT_REG', '99999999')) && this.props.page !== null} style={this.modalStyle}>
              <div className="text-center section-white m-0 py-2">
                <h4>ただいま混雑しております。</h4>
                <span>時間をおいてお申込みください。</span>
              </div>
            </Modal>
          </div>
          : this.state.not_line ?
            <div className="liff-top bg-lightwhite font-weight-bold">
              <DocumentMeta {...this.meta} />
              <div className={`page-full`}>
              </div>
              <Modal isOpen={this.state.not_line} style={this.modalStyle}>
                <div className="text-center section m-0 py-2">
                  <span>LINEをご利用ください。</span>
                </div>
              </Modal>
            </div>
            : this.state.shopId === null && this.state.shops !== undefined?
              <div className="liff-top bg-lightwhite font-weight-bold">
                <DocumentMeta {...this.meta} />
                {this.renderHeader()}
                <div className={`page-full`}>
                  {this.renderShops()}
                </div>
              </div>
              :
              <Loading />
    )
  }
}

export default withRouter(Order);