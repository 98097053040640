import React, { useState, useEffect, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import DocumentMeta from 'react-document-meta';
import MediaQuery from "react-responsive";
import axios from 'axios';
import DisplayLinker from '../Components/Display/DisplayLinker';
import DisplayOrderCounter from '../Components/Display/DisplayOrderCounter';
import DisplayOrder from '../Components/Display/DisplayOrder';
import { ToastProvider } from 'react-toast-notifications';
import Signage from '../Components/Display/Signage';
import SmartSignage from '../Components/Display/SmartSignage';
import Ticket from '../Components/Display/Ticket';
import OpenDataSlice from '../Slices/OpenData';
//import DisplayLineNav from '../Components/Display/DisplayLineNav';
import Modal from 'react-modal';
import Common from '../Components/Common/common.js';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    }, [dispatch, actions] 
  )
};

const DisplayApp = (props) => {
  const initSettings = async (shopId) => {
    const reg_params = {
      "operator": "and",
      "where": [{ "shop_id": shopId }]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`, reg_params)).data;
    setSettings(data);
    console.log(data);
    const temp = {
      link: {
        rel: {
          icon: `/${shopId}/favicon.ico`,
          "apple-touch-icon": `/${shopId}/logo192.png`,
          manifest: `/${shopId}/manifest.json`,
        },
      },
      title: data.find(s => s.setting_type === 'TITLE_NAME').setting_val,
      description: data.find(s => s.setting_type === 'TITLE_NAME').setting_val,
    };
    setMeta(temp);
  };

  const openDataActions = useActions(OpenDataSlice.actions);
  const openData = useSelector(state => state.OpenData);
  const [settings, setSettings] = useState(null);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    initSettings(props.match.params.shopId);
  }, [props.match.params.shopId]);

  const _openDataProps = { openData, openDataActions, settings, ...props };
  return (
    <div className="h-200 bg-lightwhite">
      <DocumentMeta {...meta} />
      <Modal isOpen={Common.checkContractPeriod(settings) === false}>
        <div className="text-center section-white m-0 py-2">
          <h4>サービス契約期間外です</h4>
        </div>
      </Modal>
      <ToastProvider>
        {/*<DisplayTitle />*/}
        <Route exact path={`${props.match.path}`} >
          <div className="d-flex bg-white align-items-stretch">
            <DisplayOrderCounter {..._openDataProps}/>
          </div>
          <div className="d-flex bg-white align-items-stretch justify-content-center">
            {/*<DisplayLineNav {..._openDataProps}/>*/}
            <DisplayOrder  {..._openDataProps}/>
          </div>
        </Route>
        <Route exact path={`${props.match.path}signage`} >
          <MediaQuery minWidth={1280}>
            <MediaQuery minHeight={930}>
              <Signage {..._openDataProps}/>
            </MediaQuery>
            <MediaQuery maxHeight={929}>
              <SmartSignage {..._openDataProps}/>
            </MediaQuery>
          </MediaQuery>
          <MediaQuery maxWidth={1279}>
            <SmartSignage {..._openDataProps}/>
          </MediaQuery>
        </Route>
        <Route exact path={`${props.match.path}signage/:useVoice`} >
          <MediaQuery minWidth={1280}>
            <MediaQuery minHeight={930}>
              <Signage {..._openDataProps}/>
            </MediaQuery>
            <MediaQuery maxHeight={929}>
              <SmartSignage {..._openDataProps}/>
            </MediaQuery>
          </MediaQuery>
          <MediaQuery maxWidth={1279}>
            <SmartSignage {..._openDataProps}/>
          </MediaQuery>
        </Route>
        <Route exact path={`${props.match.path}smartsignage`} >
          <SmartSignage {..._openDataProps}/>
        </Route>
        <Route exact path={`${props.match.path}smartsignage/:useVoice`} >
          <SmartSignage {..._openDataProps}/>
        </Route>
        <Route exact path={`${props.match.path}ticket`} >
          <Ticket />
        </Route>
        <Route exact path={`${props.match.path}linker`} >
          <DisplayLinker {..._openDataProps}/>
        </Route>
      </ToastProvider>
    </div>
  );
}

export default DisplayApp;