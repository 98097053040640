import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import Common from '../Common/common.js';
import Modal from 'react-modal';

const EntryTypeEdit = (props) => {
  const { entry_type } = useParams();
  const { user } = props;
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const [entryType, setEntryType] = useState({ entry_type: '', entry_type_name: '', entry_type_short_name: '', required: '', required_disp: '',  remarks: '', m_entry_data: '' });
  const [entryData, setEntryData] = useState(null);
  const { addToast } = useToasts({});
  const [addData, setAddData] = useState({ input_type: "", entry_seq: '', entry_name: '', entry_data: '', param_name: '', default_value: '', range_from: '', range_to:'' });
  const [confirm, setConfirm] = useState(false);
  const [Idx, setIdx] = useState(null);

  const modalConfirmStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '20%',
      right: '20%',
      top: '20%',
      bottom: 'auto',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto",
      maxHeight: "60%",
      width: "60%",
      overflowX: "hidden",
      overflowY: "scroll",
    }
  };

  const refreshEntryType = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (entry_type !== undefined) {
      const params = {
        shop_id: props.match.params.shopId,
        entry_type: entry_type
      }
      const getEntryType = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/data`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setEntryType(getEntryType);

      const filter = {
        "operator": "and",
        "where": [
          { shop_id: props.match.params.shopId },
          { entry_type: entry_type },
        ],
      };
      const getEntryData = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_data/search/`, filter, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      getEntryData.sort((a, b) => a.entry_seq < b.entry_seq ? -1 : 1);
      setEntryData(getEntryData);
    } else {
      setEntryType({ entry_type: '', entry_type_name: '', entry_type_short_name: '', required: 0, required_disp: 0, remarks: '' });
      setEntryData([]);

    }
  },[entry_type, props.match.params.shopId]);

  // 単一レコード　val値change
  //change entryType 
  const handleChange = (data) => (event) => {
    const target = event.target;
    const name = target.name;
    setEntryType({ ...data, [name]: target.value });
  }

  //change addData
  const handleChangeAdd = (data) => (event) => {
    const target = event.target;
    const name = target.name;
    var value = target.type === "select" ? target.selected : target.value;
    if (name === "input_type") {
      if (target.value === "text" || target.value === "number" || target.value === "date") {
        data.entry_data = "";
      }
      if(target.value !== "date"){
        data.range_from = "";
        data.range_to = "";
      }
    }
    setAddData({ ...data, [name]: value });
  }

  //change entryData 
  const handleChangeData = (data, idx) => (event) => {
    const target = event.target;
    const name = target.name;
    var _entryData = [...data];
    var value = target.type === "select" ? target.selected : target.value;
    _entryData[idx] = { ..._entryData[idx], [name]: value };
    setEntryData(_entryData);
    if (name === "input_type") {
      if (target.value === "text" || target.value === "number" || target.value === "date") {
        _entryData[idx] = { ..._entryData[idx], entry_data: "" };
        setEntryData(_entryData)
      }
      if(target.value !== "date"){
        _entryData[idx] = { ..._entryData[idx], range_from: "", range_to:"" };
        setEntryData(_entryData)
      }
    }
  }
  
  const defaultValueClick = (idx) =>{
    setConfirm(true);
    setIdx(idx);
  }

  //更新ボタン・新規登録ボタン押下時
  const handleUpdateRegisterClick = async (entryType, entryData) => {
    const jwt = localStorage.getItem('jwt');
    const nowYear = new Date().getFullYear();

    try {
      //重複チェック
      const uniqueEntryData = entryData.filter((_entrydata, index, array) => {
        return array.findIndex(_entrydata2 => _entrydata.entry_data === _entrydata2.entry_data) === index;
      });
      const uniqueInputType = entryData.filter((_entrydata, index, array) => {
        return array.findIndex(_entrydata2 => _entrydata.input_type === _entrydata2.input_type) === index;
      });

      // 入力チェック（entry_tyoe）
      if (!entryType.entry_type) {
        throw new Error('【入力区分】入力区分を入力してください');
      }
      if (!entryType.entry_type_name) {
        throw new Error('【入力区分名】入力区分名を入力してください');
      }
      if (entryType.entry_type.length > 10) {
        throw new Error('【入力区分】入力区分は10文字以下で入力してください');
      }
      if (entryType.entry_type_name.length > 100) {
        throw new Error('【入力区分名】入力区分名は100文字以下で入力してください');
      }
      if (entryType.entry_type_short_name && entryType.entry_type_short_name.length >= 50) {
        throw new Error('【短縮名】短縮名は50文字以下で入力してください');
      }
      if (entryType.remarks && entryType.remarks.length > 100) {
        throw new Error('【備考】備考は100文字以下で入力してください');
      }

      // 入力チェック（entry_data）
      entryData.forEach((data) => {
        if (!data.input_type || data.input_type === "選択") {
          throw new Error('【入力方式】入力方式を選択してください');
        }
        if (entryData.length !== 0 && uniqueInputType.length !== 1) {
          throw new Error('【入力方式】入力方式が複数種類存在します。入力方式を統一してください');
        }
        if (!data.entry_name && (data.input_type === "radio" || data.input_type === "select" || data.input_type === "check" || data.input_type === "toggle")) {
          throw new Error('【入力名】下記の入力方式をを利用する場合、入力名を入力してください。\n ラジオボタン・チェックボックス・セレクトボックス・トグルボタン');
        }
        if (!data.entry_data && (data.input_type === "radio" || data.input_type === "select" || data.input_type === "check" || data.input_type === "toggle")) {
          throw new Error('【入力内容】入力内容を入力してください');
        }
        if (data.entry_name && data.entry_name.length > 100) {
          throw new Error('【入力名】入力名は100文字以下で入力してください');
        }
        if (data.entry_data && data.entry_data.length > 200) {
          throw new Error('【入力内容】入力内容は100文字以下で入力してください');
        }
        if (entryData.length !== 0 && entryData.length !== uniqueEntryData.length &&
          (uniqueInputType[0].input_type === "radio" || uniqueInputType[0].input_type === "select" || uniqueInputType[0].input_type === "check" || uniqueInputType[0].input_type === "toggle")) {
          throw new Error('【入力内容】入力内容に重複があります。入力内容を一意にしてください');
        }
        if (data.default_value && data.default_value.length > 100) {
          throw new Error('【初期値】初期値は100文字以下で入力してください');
        }
        if (data.input_type === "number" && !data.default_value.match(/^[0-9]+$/)) {
          throw new Error('【初期値】入力方式が数値の場合は、初期値を0以上の半角整数で入力してください');
        }
        if (data.default_value && data.input_type === "date" && !Common.checkDate(data.default_value)) {
          throw new Error('【初期値】不正な日付が入力されています。正しい日付を入力してください※例:2000/01/01');
        }
        if (data.range_from && !data.range_from.match(/^[0-9]+$/)) {
          throw new Error('【表示開始年】不正な年数が入力されています。0以上の半角整数で入力してください。');
        }
        if (data.range_to && !data.range_to.match(/^[0-9]+$/)) {
          throw new Error('【表示終了年】不正な年数が入力されています。0以上の半角整数で入力してください。');
        }
        if ((data.range_from && data.range_to && data.range_from > data.range_to) 
            || (!data.range_from && data.range_to && nowYear > data.range_to) 
            || (data.range_from && !data.range_to && data.range_from > nowYear)) {
          throw new Error('【表示開始年/表示終了年】表示開始年と表示終了年の時間関係が不正です。');
        }
        if (data.input_type === "label" && (Number(entryType.required) !== 0 || Number(entryType.required_disp) !== 0)) {
          throw new Error('入力方式がラベルの場合、必須区分は「任意」を選択してください。');
        }
      });

    } catch (err) {
      window.alert(err);
      return;
    }

    // 確認メッセージ
    if (entry_type === undefined) {
      if (!window.confirm(`ユーザマスタ[${entryType.entry_type}:${entryType.entry_type_name}]を登録します。よろしいですか？`)) {
        return;
      }
    } else {
      if (!window.confirm(`ユーザマスタ[${entryType.entry_type}:${entryType.entry_type_name}]を更新します。よろしいですか？`)) {
        return;
      }
    }

    const entryTypeParams = {
      shop_id: props.match.params.shopId,
      entry_type: entryType.entry_type,
      entry_type_name: entryType.entry_type_name || "",
      entry_type_short_name: entryType.entry_type_short_name || "",
      required: entryType.required,
      required_disp: entryType.required_disp,
      remarks: entryType.remarks || "",
      upd_name: entryType.upd_name,
      upd_date: entryType.upd_date,
      entryData,
    };

    let res;
    try {
      setLockData(true);
      console.log(entry_type);
      if (entry_type !== undefined) {
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/entry_type/update`, entryTypeParams, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        console.log(res);

      } else {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/insert`, entryTypeParams, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        if (res.data.message === '他のユーザーにより削除されています。') {
          history.replace(`${generatePath(`${props.match.path}entrytype`, { shopId: props.match.params.shopId })}`);
        }
      } else if (entry_type !== undefined) {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshEntryType();
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        history.replace(`${generatePath(`${props.match.path}entrytype`, { shopId: props.match.params.shopId })}`);
      }
      setLockData(false);
    }
  }

  //削除ボタン押下時
  const handleDeleteClick = async (entrytype) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`店舗設定マスタ[${entrytype.entry_type}:${entrytype.entry_type_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      shop_id: entrytype.shop_id,
      entry_type: entrytype.entry_type,
      upd_date: entrytype.upd_date
    };

    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/delete`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });

    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        if (res.data.message === '他のユーザーにより削除されています。') {
          history.replace(`${generatePath(`${props.match.path}entrytype`, { shopId: props.match.params.shopId })}`);
        }
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        if (!window.alert(`ユーザー管理覧画面に戻ります`)) {
          history.replace(`${generatePath(`${props.match.path}entrytype`, { shopId: props.match.params.shopId })}`);
          return;
        }
      }
      setLockData(false);
    }
  }

  //追加ボタン押下時
  const handleAddClick = async (data) => {
    const ADD_DATA = {};
    ADD_DATA.shop_id = props.match.params.shopId;
    ADD_DATA.entry_type = "";
    ADD_DATA.input_type = data.input_type;
    ADD_DATA.entry_seq = "";
    ADD_DATA.entry_name = data.entry_name;
    ADD_DATA.entry_data = data.entry_data;
    ADD_DATA.param_name = "";
    ADD_DATA.default_value = data.default_value;
    ADD_DATA.range_from = data.range_from;
    ADD_DATA.range_to = data.range_to;

    entryData.push(ADD_DATA)
    refreshAddData();
    console.log(entryData);
  }

  const refreshAddData = async () => {
    setAddData({ input_type: "", entry_name: '', entry_data: '', param_name: '', default_value: '', range_from: '', range_to:'' })
  }

  //削除ボタン押下時
  const handleDeleteDataClick = async (idx) => {
    setIdx(null);
    entryData.splice(idx, 1)
    refreshAddData();
  }

  //更新ボタン・新規登録ボタン判別
  const UpdateRegisterButton = (entrytype, entrydata) => {
    if (entry_type !== undefined) {
      //新規登録判定時
      return (
        <button type="button"
          disabled={lockData}
          className={`btn btn-primary mx-5`}
          onClick={() => handleUpdateRegisterClick(entrytype, entrydata)}>
          更新
        </button>

      )
    } else {
      //更新判定時
      return (
        <button type="button"
          disabled={lockData}
          className={`btn btn-primary mx-1`}
          onClick={() => handleUpdateRegisterClick(entrytype, entrydata)}>
          登録
        </button>
      )
    }
  }


  //削除ボタン
  const deleteButton = (data) => {
    if (entry_type !== undefined) {
      return (
        <button type="button"
          disabled={lockData || entry_type === null}
          className={`btn btn-primary mx-5`}
          onClick={() => handleDeleteClick(data)}>
          削除
        </button>
      )
    }
  }

  //戻るボタン
  const backButton = () => {
    return (
      <button type="button"
        className={`btn btn-secondary mx-5`}
        onClick={() => history.goBack()}>
        戻る
      </button>
    )
  }
  //追加ボタン
  const addButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-primary`}
        onClick={() => handleAddClick(data)}>
        追加
      </button>
    )
  }
  //削除ボタン
  const deletedataButton = (idx) => {
    return (
      <button type="button"
        className={`btn btn-primary`}
        onClick={() => handleDeleteDataClick(idx)}>
        削除
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshEntryType();
    }
    return fetchData();
  }, [refreshEntryType]);


  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {entryType !== null && (
        <div className="row mb-3 col-10">
          {/* 入力区分 */}
          <label htmlFor="entry_type" className="col-sm-3 col-form-label my-sm-3">入力区分</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" disabled={lockData || entry_type} className="form-control w-20" id="entry_type" name="entry_type" value={entryType.entry_type || ""} onChange={handleChange(entryType)}></input>
          </div>
          {/* 入力区分名 */}
          <label htmlFor="entry_type_name" className="col-sm-3 col-form-label my-sm-3">入力区分名</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id="entry_type_name" name="entry_type_name" value={entryType.entry_type_name || ""} onChange={handleChange(entryType)}></input>
          </div>

          {/* 短縮名 */}
          <label htmlFor="entry_type_short_name" className="col-sm-3 col-form-label my-sm-3">短縮名</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control w-50" id="entry_type_short_name" name="entry_type_short_name" value={entryType.entry_type_short_name || ""} onChange={handleChange(entryType)}></input>
          </div>

          <label className="w-100 mx-0 mb-0 mt-3 px-3-env ">必須区分</label>

          {/* 必須区分 （アプリ画面）*/}
          <label htmlFor="required" className="col-sm-3 col-form-label my-sm-3">　【アプリ画面】</label>
          <div className="row w-75 mx-0 px-3-env line-height-2-2">
            <div className="w-20 p-3">
              <input type="radio" className="form-control" id="required" name="required" value="1" checked={Number(entryType.required) === 1} onChange={handleChange(entryType)}></input>
            </div>
            <div className="w-20 p-3">
              <label className="w-50 ">必須</label>
            </div>
            <div className="w-20 p-3">
              <input type="radio" className="form-control" id="required" name="required" value="0" checked={Number(entryType.required) === 0} onChange={handleChange(entryType)}></input>
            </div>
            <div className="w-20 p-3">
              <label className="w-50 ">任意</label>
            </div>
          </div>
          {/* 必須区分 （整理券発行画面）*/}
          <label htmlFor="required_disp" className="col-sm-3 col-form-label my-sm-3">　【整理券発行画面】</label>
          <div className="row w-75 mx-0 px-3-env line-height-2-2">
            <div className="w-20 p-3">
              <input type="radio" className="form-control" id="required_disp" name="required_disp" value="1" checked={Number(entryType.required_disp) === 1} onChange={handleChange(entryType)}></input>
            </div>
            <div className="w-20 p-3">
              <label className="w-50 ">必須</label>
            </div>
            <div className="w-20 p-3">
              <input type="radio" className="form-control" id="required_disp" name="required_disp" value="0" checked={Number(entryType.required_disp) === 0} onChange={handleChange(entryType)}></input>
            </div>
            <div className="w-20 p-3">
              <label className="w-50 ">任意</label>
            </div>
          </div>
          {/* 備考 */}
          <label htmlFor="remarks" className="col-sm-3 col-form-label my-sm-3">備考</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control mb-5" id="remarks" name="remarks" value={entryType.remarks || ""} onChange={handleChange(entryType)}></input>
          </div>
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center" width="10%">
                  番号
                </td>
                <td className="text-center" width="22%">
                  入力方式
                </td>
                <td className="text-center ">
                  入力名
                </td>
                <td className="text-center">
                  入力内容
                </td>
                <td className="text-center ">
                  入力項目名
                </td>
                <td className="text-center" width="14.5%">
                  初期値
                </td>
                <td width="10%"></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center align-middle">
                  新規
                </td>
                <td className="text-left align-middle">
                  <select
                    disabled={props.disabled}
                    className="form-control"
                    id="input_type"
                    name="input_type"
                    value={addData.input_type}
                    onChange={handleChangeAdd(addData)}>
                    <option value="">- 選択 -</option>
                    <option value="text">テキスト</option>
                    <option value="number">数値</option>
                    <option value="radio">ラジオボタン</option>
                    <option value="check">チェックボックス</option>
                    <option value="select">セレクトボックス</option>
                    <option value="toggle">トグルボタン</option>
                    <option value="date">日付</option>
                    <option value="label">ラベル</option>
                  </select>
                </td>
                {/* 入力名 */}
                <td className="text-left align-middle">
                  <input type="text" className="form-control" id="entry_name" name="entry_name" value={addData.entry_name || ""} onChange={handleChangeAdd(addData)}></input>
                </td>
                {/* 入力内容 */}
                <td className="text-left align-middle">
                  <input type="text" disabled={addData.input_type === "text" || addData.input_type === "number" || addData.input_type === "date" || addData.input_type === "label"} className="form-control" id="entry_data" name="entry_data" value={addData.entry_data || ""} onChange={handleChangeAdd(addData)}></input>
                </td>
                {/* 入力項目名 */}
                <td className="text-left align-middle">
                  <input type="text" disabled className="form-control" id="param_name" name="param_name" value={addData.param_name || ""} onChange={handleChangeAdd(addData)}></input>
                </td>
                {/* 初期値 */}
                <td className="text-center align-middle">
                  {addData.input_type !== "date" 
                    ?  <input type="text" disabled={addData.input_type === "label"} className="form-control" id="default_value" name="default_value" value={addData.default_value || ""} onChange={handleChangeAdd(addData)}></input>      
                    :  <button type="button" className={`btn btn-primary`}  onClick={() => defaultValueClick(null)}>設定</button>
                  }
                </td>
                <td className="text-center align-middle">
                  {addButton(addData)}
                </td>
              </tr>
              {entryData !== null && entryData.map((data, idx) => (
                <tr key={idx}>
                  {/* 番号 */}
                  <td className="text-center align-middle">
                    {idx + 1}
                  </td>
                  {/* 入力方式 */}
                  <td className="text-left align-middle">
                    <select className="form-control" id="input_type" name="input_type" value={data.input_type} onChange={handleChangeData(entryData, idx)}>
                      <option value="">-選択-</option>
                      <option value="text">テキスト</option>
                      <option value="number">数値</option>
                      <option value="radio">ラジオボタン</option>
                      <option value="check">チェックボックス</option>
                      <option value="select">セレクトボックス</option>
                      <option value="toggle">トグルボタン</option>
                      <option value="date">日付</option>
                      <option value="label">ラベル</option>
                    </select>
                  </td>
                  {/* 入力名 */}
                  <td className="text-left align-middle">
                    <input type="text" className="form-control" id="entry_name" name="entry_name" value={data.entry_name || ""} onChange={handleChangeData(entryData, idx)}></input>
                  </td>
                  {/* 入力内容 */}
                  <td className="text-left align-middle">
                    <input type="text" disabled={data.input_type === "text" || data.input_type === "number" || data.input_type === "date" || data.input_type === "label"} className="form-control" id="entry_data" name="entry_data" value={data.entry_data || ""} onChange={handleChangeData(entryData, idx)}></input>
                  </td>
                  {/* 入力項目名 */}
                  <td className="text-left align-middle">
                    <input type="text" disabled className="form-control" id="param_name" name="param_name" value={data.param_name || ""} onChange={handleChangeData(entryData, idx)}></input>
                  </td>
                  {/* 初期値 */}
                  <td className="text-center align-middle">
                  {data.input_type !== "date"  
                    ?  <input type="text" disabled={data.input_type === "label"} className="form-control" id="default_value" name="default_value" value={data.default_value || ""} onChange={handleChangeData(entryData, idx)}></input>
                    :  <button type="button" className={`btn btn-primary`} onClick={() => defaultValueClick(idx)}>設定</button>
                  }
                  </td>
                  <td className="text-center align-middle">
                    {deletedataButton(idx)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="col-12 text-right">※【修正該当箇所】エラーメッセージ</div>

          {/* 更新ボタン・新規登録ボタン　/削除ボタン */}
          <div className="col-sm-9 my-sm-5 text-right">
            {UpdateRegisterButton(entryType, entryData)}
            {deleteButton(entryType)}
            {backButton()}
          </div>
        </div>

      )}

      <Modal isOpen={confirm} style={modalConfirmStyle}>
        <table className="table table-bordered table-striped mb-2">
          <thead className={`table-info`}>
            <tr>
              <td className="text-center">
                初期値
              </td>
              <td className="text-center">
                表示開始年
              </td>
              <td className="text-center ">
                表示終了年
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className=''>
              {/* 初期値 */}
              <td className="text-left align-middle">
                <input 
                  type="text" 
                  className="form-control" 
                  id="default_value" 
                  name="default_value" 
                  value = {Idx === null ? addData.default_value || "" : entryData[Idx].default_value || ""} 
                  onChange = {Idx === null ? handleChangeAdd(addData) : handleChangeData(entryData, Idx)}>
                </input>
              </td>
              {/* 表示開始年 */}
              <td className="text-left align-middle">
                <input 
                  type="text" 
                  className="form-control" 
                  id="range_from" 
                  name="range_from" 
                  value = {Idx === null ? addData.range_from || "" : entryData[Idx].range_from || ""} 
                  onChange = {Idx === null ? handleChangeAdd(addData) : handleChangeData(entryData, Idx)}>
                </input>
              </td>
              {/* 表示終了年 */}
              <td className="text-left align-middle">
                <input 
                  type="text" 
                  className="form-control" 
                  id="range_to" 
                  name="range_to"
                  value = {Idx === null ? addData.range_to || "" : entryData[Idx].range_to || ""} 
                  onChange = {Idx === null ? handleChangeAdd(addData) : handleChangeData(entryData, Idx)}>
                </input>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="text-right">※表示開始年/表示終了年は入力欄が空の場合、現在の年が適用されます。</div>
        <div className="col text-center my-2">
          <button type="button" className={`btn btn-secondary mx-5 text-center`} onClick={() => setConfirm(false)}>編集画面に戻る</button>
        </div>
      </Modal>
    </div>

  )
}

export default EntryTypeEdit