import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';


const DisplayOrderCounter = (props) => {
  const { openDataActions, settings } = props;
  const [wait_count, setWaitCount] = useState(null);

  const refreshOrderCount = useCallback(async () => {
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/${props.match.params.shopId}`)).data;
    setWaitCount(data.wait_count);
    openDataActions.set(data.open_data);
  },[props.match.params.shopId, openDataActions])

  useEffect(() => {
    var intervalId;
    async function fetchData() {
      await refreshOrderCount();
      intervalId = setInterval(() => {
        refreshOrderCount();
      }, 5000);
    }
    fetchData();
    return () => {
      clearInterval(intervalId);
    };
}, [refreshOrderCount]);

  const getLogoImage = () => {
    if (settings) {
      // ロゴ画像ファイル
      const logo_file = settings.find(s => s.setting_type === 'LOGO_FILE').setting_val;
      const IconFile= require(`../Images/${logo_file}`).default;
      return <img className="mh-150px" src={IconFile} onError={e => e.target.style.display = 'none'} alt="ロゴ画像" />
    }
  }

  return (
    <div className="container my-3">
      <div className="row text-center px-0 py-1 mt-1 mb-1">
        <div className="col-4">
          {getLogoImage()}
        </div>
        {wait_count !== null && (
          <div className='col-7'>
            <div className='row h-100'>
              <div className="col bg-white px-0 py-2 mt-4">
                <h2 className="d-inline mb-0">現在 </h2>
                <h1 className="mb-0 text-danger d-inline font-weight-bold">{wait_count ? wait_count[0].count_order : 0}</h1>
                <h2 className="d-inline mb-0"> {settings && settings.find(s => s.setting_type === 'LABEL_WAIT_COUNT_UNIT').setting_val}待ち</h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DisplayOrderCounter;