import React from 'react';

const ControlCheck = (props) => {

  const fontLabel = props.target === "sp" ? "" : "font-h2 py-2";
  const sizeForm = props.target === "sp" ? "" : "form-control-lg";
  const positionForm = props.target === "sp" ? "" : "py-1";

  return (
    <div className="row w-100 mx-0 px-3-env line-height-2-2 p-1 ml-1">
      <div className={`w-25 ${positionForm}`}>
        <input
          type="checkbox"
          disabled={props.disabled}
          className={`form-control ${sizeForm}`}
          id={props.entryData.entry_type + "-" + props.entryData.entry_seq}
          name={props.entryData.param_name}
          value={props.entryData.entry_data}
          checked={props.value === props.entryData.entry_data}
          onChange={(e) => { props.setState(props.entryData.param_name, (e.target.checked ? e.target.value : "")) }}></input>
      </div>
      <div className={`w-75 ${fontLabel}`}>
        <label htmlFor={props.entryData.entry_type + "-" + props.entryData.entry_seq} className="w-100">{props.entryData.entry_name}</label>
      </div>
    </div>
  )

}

export default ControlCheck;
