import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import util from 'util';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
// import BackyardOrder from '../Components/BackyardOrder/BackyardOrder';
// import BackyardOrderFin from '../Components/BackyardOrder/BackyardOrderFin';
import { ToastProvider } from 'react-toast-notifications';
import BackyardOrderFinTab from '../Components/BackyardOrder/BackyardOrderFinTab';
import Setting from '../Components/Master/Setting';
import constClass from '../Constants/Constants';
import Modal from 'react-modal';
import Common from '../Components/Common/common.js';
import BackyardUser from '../Components/Master/BackyardUser';
import BackyardUserEdit from '../Components/Master/BackyardUserEdit';
import Calendar from '../Components/Master/Calendar';
import ShopEdit from '../Components/Master/ShopEdit';
import EntryType from '../Components/Master/EntryType';
import EntryTypeEdit from '../Components/Master/EntryTypeEdit';
import HistoryDL from '../Components/BackyardOrder/HistoryDL';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    }, [dispatch, actions]
  )
};

const BackApp = (props) => {
  const initSettings = async (shopId) => {
    const reg_params = {
      "operator": "and",
      "where": [{ "shop_id": shopId }]
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`, reg_params)).data;
    setSettings(data);
    console.log(data);
    const temp = {
      link: {
        rel: {
          icon: `/${shopId}/favicon.ico`,
          "apple-touch-icon": `/${shopId}/logo192.png`,
          manifest: `/${shopId}/manifest.json`,
        },
      },
      title: data.find(s => s.setting_type === 'TITLE_NAME') ? data.find(s => s.setting_type === 'TITLE_NAME').setting_val : '',
      description: data.find(s => s.setting_type === 'TITLE_NAME') ? data.find(s => s.setting_type === 'TITLE_NAME').setting_val : '',
    };
    setMeta(temp);
  };
  
  const [settings, setSettings] = useState(null);
  const [meta, setMeta] = useState({});
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);


  useEffect(() => {
    initSettings(props.match.params.shopId);
  }, [props.match.params.shopId]);


  const _userProps = { user, userActions, settings, ...props };
  return (
    <div className='backyard-top'>
      <div className="row d-none"><div className="col-12">{util.inspect(settings)}</div></div>
      <DocumentMeta {...meta} />
      <Modal isOpen={Common.checkContractPeriod(settings) === false}>
        <div className="text-center section-white m-0 py-2">
          <h4>サービス契約期間外です</h4>
        </div>
      </Modal>
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider  placement="bottom-center">
          <Route render={() =>
            <UserOnly {..._userProps} >
              <Route exact path={`${props.match.path}`} >
                {/* <BackyardOrder {..._userProps} /> */}
                <BackyardOrderFinTab sts={constClass.STATUS.REG} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}fin`} >
                <BackyardOrderFinTab sts={constClass.STATUS.FIN} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}ccl`} >
                <BackyardOrderFinTab sts={constClass.STATUS.CCL} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}abs`} >
                <BackyardOrderFinTab sts={constClass.STATUS.ABS} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}user/edit/:id`} >
                <BackyardUserEdit {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}user/edit`} >
                <BackyardUserEdit {..._userProps} />
              </Route>
              <Route path={`${props.match.path}setting`} >
                <Setting {..._userProps} />
              </Route>
              <Route path={`${props.match.path}shop`} >
                <ShopEdit {..._userProps} />
              </Route> 
              <Route exact path={`${props.match.path}historydl`} >
                <HistoryDL {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}calendar`} >
                <Calendar {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}user`} >
                <BackyardUser {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}entrytype`} >
                <EntryType {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}entrytype/edit`} >
                <EntryTypeEdit {..._userProps} />
              </Route>
              <Route exact path={`${props.match.path}entrytype/edit/:entry_type`} >
                <EntryTypeEdit {..._userProps} />
              </Route>
            </UserOnly>
          } />
          <Route render={() =>
            <GuestOnly {..._userProps} >
              <Route path={`${props.match.path}login`} render={() =>
                <Login {..._userProps} />
              } />
            </GuestOnly>
          } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

//<Route render={() => <NavBar {..._userProps} />} />
export default BackApp;