import React from 'react';

const ControlNumber = (props) => {

  const colButton = props.target === "sp" ? "col-auto" : "col-3";
  const colNumber = props.target === "sp" ? "col" : "col-3";
  const fontButton = props.target === "sp" ? "" : "font-h2";
  const fontNumber = props.target === "sp" ? "font-h4" : "font-h2";

  /**
   * 数値入力コントロール（＋）
   */
  const addCount = () => {
    var val = isFinite(props.value) ? Number(props.value) : 0;
    var count = val + 1;
    props.setState(props.entryData.param_name, count);
  }

  /**
   * 数値入力コントロール（－）
   */
  const subCount = () => {
    var val = isFinite(props.value) ? Number(props.value) : 0;
    var count = (val - 1 < 0 ? 0 : val - 1);
    props.setState(props.entryData.param_name, count);
  }


  return (
    <React.Fragment>
      <div className={`${colButton} text-center bg-lightwhite p-1 ml-1`}>
        <button
          disabled={props.disabled}
          className={`btn btn-active w-100 h-100 py-2 ${fontButton}`}
          onClick={() => { subCount() }}>
          －
          </button>
      </div>
      <div className={`${colNumber} text-center bg-lightwhite py-2 px-1 align-self-center`}>
        <div className={`bg-white ${fontNumber}`}>{props.value ? props.value : 0}</div>
      </div>
      <div className={`${colButton} text-center bg-lightwhite p-1 mr-1`}>
        <button
          disabled={props.disabled}
          className={`btn btn-active w-100 h-100 py-2 ${fontButton}`}
          onClick={() => { addCount() }}>
          ＋
          </button>
      </div>
    </React.Fragment>
  )

}

export default ControlNumber;
