import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';

const BackyardUserEdit = (props) => {
  const { id } = useParams();
  const { user } = props;
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const [userData, setUserData] = useState({ user_id: '', login_id:'', user_name: '', user_class: '', user_short: '',  password: ''});
  const { addToast } = useToasts();

  const refreshUser = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if(id !== undefined){
      const getUser = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setUserData(getUser);
    }else{
      setUserData({login_id: '', user_id: '', user_name: '', user_class: '', user_short: '',  password: ''});
    }
  },[id])

  // 単一レコード　val値change
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setUserData({ ...userData, [name]: value });

  }


  //更新ボタン・新規登録ボタン押下時
  const handleUpdateRegisterClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (!data.login_id) {
      window.alert('ログインIDを入力してください。');
      return;
    }
    if (!data.user_name) {
      window.alert('ユーザー名を入力してください。');
      return;
    }
    if (!data.user_class) {
      window.alert('クラス区分を選択してください。');
      return;
    }
    if (!data.user_short) {
      window.alert('ユーザー名(表示用)を入力してください。');
      return;
    }
    if (!data.password) {
      window.alert('パスワードを入力してください。');
      return;
    }

    // 確認メッセージ
    if( data.user_id !== '' ){
      if (!window.confirm(`ユーザマスタ[${data.user_id}:${data.user_name}]を更新します。よろしいですか？`)) {
        return;
      }
    } else {
      if (!window.confirm(`ログインID[${data.login_id}]を登録します。よろしいですか？`)) {
        return;
      }
    }

    const params = {
      user_id: data.user_id,
      login_id: data.login_id,
      shop_id: props.match.params.shopId,
      user_name: data.user_name,
      user_class: data.user_class,
      user_short: data.user_short,
      password: data.password,
      upd_name: data.upd_name,
      upd_date: data.upd_date,
    };

    let res;
    try {
      setLockData(true);
      console.log(id);
      if( data.user_id !== '' ){
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/user/update/${data.user_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      }else{
        console.log(data);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/insert`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });

      } else if(data.user_id !== ''){
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshUser();
      }else{
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        history.replace(`${generatePath(`${props.match.path}user`, { shopId: props.match.params.shopId })}`);
      }
      setLockData(false);
    }
  }

  //削除ボタン押下時
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`ユーザマスタ[${data.user_id}:${data.user_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      user_id: data.user_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      console.log(params);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/delete`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        if (!window.alert(`ユーザー管理覧画面に戻ります`)) {
          history.replace(`${generatePath(`${props.match.path}user`, { shopId: props.match.params.shopId })}`);
          return;
        }
      }
      setLockData(false);
    }
  }
  
  //更新ボタン・新規登録ボタン判別
  const UpdateRegisterButton = (data) => {
    if(data.user_id !== ''){
      //更新判定時
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-5`}
        onClick={() => handleUpdateRegisterClick(data)}>
        更新
      </button>
    )
    }else{
      //新規登録判定時

      return (
        <button type="button"
          disabled={lockData}
          className={`btn btn-primary mx-1`}
          onClick={() => handleUpdateRegisterClick(data)}>
          登録
        </button>
      )
    }
  }

//削除ボタン
  const deleteButton = (data) => {
    if(data.user_id !== ''){
        return (
          <button type="button"
            disabled={lockData || data.user_id === null}
            className={`btn btn-primary mx-5`}
            onClick={() => handleDeleteClick(data)}>
            削除
          </button>
        )
      }
    }


  //戻るボタン

  const backButton = () => {
    return (
      <button type="button"
        className={`btn btn-secondary mx-5`}
        onClick={() => history.goBack()}>
        戻る
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshUser();
    }
    return fetchData();
  }, [refreshUser]);

  return (

    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {userData !== null && (

        <div className="row mb-3 col-10">

          {/* ログインID */}
         <label htmlFor="login_id" className="col-sm-3 col-form-label my-sm-3">ログインID</label>
          <div className="col-sm-9 my-sm-3">
           <input type="text" className="form-control" id="login_id" name="login_id" value={userData.login_id} onChange={handleChange}></input>
         </div>

          {/* ユーザー名 */}
          <label htmlFor="user_name" className="col-sm-3 col-form-label my-sm-3">ユーザー名</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id="user_name" name="user_name" value={userData.user_name} onChange={handleChange}></input>
          </div>
          
          {/* クラス区分 */}
          <label htmlFor="user_id" className="col-sm-3 col-form-label my-sm-3">クラス区分</label>
          <div className="col-sm-9 a my-sm-3 text-left">
            <select className="custom-select" name="user_class" value={userData.user_class} onChange={handleChange}>
              <option value="">選択してください</option>
              <option value={constClass.CLASS.COUNTER}>{constClass.CLASS_NAME.COUNTER}</option>
              <option value={constClass.CLASS.ADMIN}>{constClass.CLASS_NAME.ADMIN}</option>
            </select>
          </div>

          {/* ユーザー名（表示用） */}
          <label htmlFor="user_short" className="col-sm-3 col-form-label my-sm-3">ユーザー名(表示用)</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id="user_short" name="user_short" value={userData.user_short} onChange={handleChange}></input>
          </div>

          {/* パスワード */}
          <label htmlFor="password" className="col-sm-3 col-form-label my-sm-3">パスワード</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control" id="password" name="password" value={userData.password} onChange={handleChange}></input>
          </div>

          {/* 更新ボタン・新規登録ボタン　/削除ボタン */}
          <div className="col-sm-9 my-sm-5 text-right">
            {UpdateRegisterButton(userData)}
            {deleteButton(userData)}
            {backButton()}
          </div>
        </div>

      )}
    </div>

  )
}

export default BackyardUserEdit